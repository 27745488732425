import { createSlice } from '@reduxjs/toolkit';

export const RightSidebar=createSlice({
  name:"rightSidebar",
  initialState: false,
  reducers:{
    setRightSidebar:(state, action)=>{
      state=action.payload
      return state
    },
    toggleRightSidebar:(state)=>{
      console.log("toggleRightSidebar", state)
      return !state
    }
  }
})

export const { setRightSidebar, toggleRightSidebar } = RightSidebar.actions

export default RightSidebar.reducer