import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Button, FormControl,TextField,colors } from '@mui/material';
import AdminService from '../../services/api.js';
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

const PREFIX = 'ForgotPassword';

const classes = {
  loginSuccessButton: `${PREFIX}-loginSuccessButton`,
  backButton: `${PREFIX}-backButton`,
  formGroup: `${PREFIX}-formGroup`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: {palette, mode}
}) => ({
  [`& .${classes.loginSuccessButton}`]: {
    color: "#ffffff",
    backgroundColor: colors.green[400],
    '&:hover': {
      backgroundColor: colors.green[600],
    },
    padding: "10px 19px",
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    '& span': {
      paddingTop: "1px",
      alignItems: "start",
    },
    width: "100%"
  },

  [`& .${classes.backButton}`]: {
    backgroundColor: colors.grey[400],
    '&:hover': {
      backgroundColor: colors.grey[500],
    },
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    padding: "3px 12px 3px 10px",
    '& span': {
      paddingTop: "1px",
      alignItems: "start",
    },
  },

  [`& .${classes.formGroup}`]: {
    '& span.MuiFormControlLabel-label': {
      marginTop: "4px"
    }
  }
}));

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

const ForgotPassword = (props) => {
    const { hideForgotPassword } = props;
    const [email, setEmail] = useState('');
    const [errorsEmail, setErrorsEmail] = useState('');
    const [disableButton, setDisableButton] = useState(false);

    const emailChanged = (event) => {
      const val = event.target.value?.trim()
      setEmail(val)
      if (val && validEmailRegex.test(val)) { 
        setErrorsEmail('');
      }
    }

    const emailKeyUp = (event) => {
      if(event.which == 13) {
        handleClickSend()
      }
    }
      
    const handleClickSend = async () => {
      const waitToast = toast.info('Please wait...', {autoClose: false})
      try{
        setDisableButton(true)
        if (!email || !validEmailRegex.test(email)) { 
          setErrorsEmail('The email address is not valid');
          return
        }
        const response = await AdminService.forgotPassword({email, type: 1})
        if (response.data.code !== 200) {
          toast.error(response.data.message);
        } else {
          toast.success(`
            If you are registered in VS, an email has been sent to you. 
            Please follow the steps in that email to reset your password.
          `, {autoClose: false});
        }
      } catch (err) {
        console.error(err)
        toast.error("internal server error");
      } finally {
        setDisableButton(false)
        toast.dismiss(waitToast)
      }
    }
    return (
      <Root>
        <div className="w-100 d-flex justify-content-center">
          <Button
            className={classes.backButton}
            startIcon={<ArrowBack />}
            onClick={hideForgotPassword}
            title="Back to login"
            style={{ position: "absolute", left: "15px" }}
          >
            Login
          </Button>
          <h4>Reset password</h4>
        </div>
        <div className="w-100 d-flex mt-3">
          <h6>
            Please enter your email address. You will receive a link to reset
            your password.
          </h6>
        </div>
        <div className="w-100 d-flex justify-content-center mt-3">
          <FormControl fullWidth variant="standard">
            <TextField
              error={!!errorsEmail}
              helperText={errorsEmail || ""}
              value={email}
              onChange={emailChanged}
              onKeyUp={emailKeyUp}
              label="Email Address"
            />
          </FormControl>
        </div>
        <div className="w-100 d-flex justify-content-center mt-3">
          <Button
            className={classes.loginSuccessButton}
            startIcon={<SendIcon />}
            onClick={handleClickSend}
            disabled={disableButton}
          >
            Send
          </Button>
        </div>
      </Root>
    );
};

export default ForgotPassword;