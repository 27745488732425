import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Draggable from 'react-draggable';

const Root = styled('div')(({
  theme: {palette, mode}
}) => ({
    ['& .modal-content']: {
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
}))

/**
 *  show: boolean, true to show the modal, false to hide
 *  size: string, 'sm', 'md', 'lg', 'xl' default is 'md'
 *  closeButton: pass boolean or jsx element. true to show the default close button, false to don't show close button
 *  onCloseButton: function to be called when close button is clicked (if close button is set to true)
 *  header: jsx element, will be placed inside div.modal-header
 *  body: jsx element, will be placed inside div.modal-body
 *  footer: jsx element, will be placed inside div.modal-footer
 *  bodyClassOverride: string, will be passed as class name to the modal body element. (the element with 'modal-body' class)
 *  onCloseButton: function to be called when close backdrop of the modal is clicked ot touched
 */
const DraggableModal = ({ 
  show = false, 
  size = "md", 
  closeButton, 
  onCloseButton, 
  header = "", 
  body = "", 
  footer = "", 
  bodyClassOverride = "", 
  onBackdropClick 
}) => {
  const [closeJsx, setCloseJsx] = useState("")
  const modalContentRef = useRef(null);

  useEffect(() => {
    const listenForBackdropClick = (event) => {
      if (modalContentRef.current && !modalContentRef.current.contains(event.target) && onBackdropClick) {
        onBackdropClick();
      }
    }

    if(show) {
      if(typeof onBackdropClick === "function") {
        document.addEventListener("touchend", listenForBackdropClick);
        document.addEventListener("mousedown", listenForBackdropClick);
      }
    } else {
      document.removeEventListener("touchend", listenForBackdropClick);
      document.removeEventListener("mousedown", listenForBackdropClick);
    }

    return () => {
      document.removeEventListener("touchend", listenForBackdropClick);
      document.removeEventListener("mousedown", listenForBackdropClick);
    }
  }, [show, onBackdropClick])

  useEffect(() => {
    if(closeButton === true) {
      setCloseJsx(
        <button className="close" style={{borderRadius:"50%",width:"50px",height:"50px"}}
          title='Close' onClick={onCloseButton}>×</button>
      )
    } else if (closeButton) {
      setCloseJsx(closeButton)
    } else {
      setCloseJsx("")
    }
  }, [closeButton])

  return (
    <Draggable handle='.modal-header'>
      <Root role="dialog" aria-modal="true" style={{pointerEvents: "none", display: "block"}} 
        className={"fade modal " + (show ? " show " : " hide ")} tabindex="-1">
        <div className={`modal-dialog modal-${size} modal-dialog-centered`}>
          <div className="modal-content" ref={modalContentRef}>
            <div className="modal-header" style={{cursor: "move"}}>
              {header}
              {closeJsx}
            </div>
            <div className={"modal-body " + (bodyClassOverride || "")} style={{overflow:"auto", maxHeight:"80vh"}}>
              {body}
            </div>
            {footer && 
              <div className="modal-footer">
                {footer}
              </div>
            }
          </div>
        </div>
      </Root>
    </Draggable>
  );
}
export default DraggableModal