import React, { useState, useEffect, useRef, useContext } from 'react'
import { styled } from '@mui/material/styles';
import apiServices from '../../services/api'
import { SocketContext } from '../../context/socket';
import { toast } from 'react-toastify';
import Storages from '../../constants/storages';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  Slider
} from "@mui/material";
import DraggableModal from '../Modal/DraggableModal';
import { Stack } from '@mui/material';

const PREFIX = 'UserDeviceManagement';

const classes = {
  color: `${PREFIX}-color`,
  modalFormControl: `${PREFIX}-modalFormControl`,
  modalFormSelect: `${PREFIX}-modalFormSelect`,
  testSpeakerBtn: `${PREFIX}-testSpeakerBtn`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { palette, mode }
}) => ({
  [`& .${classes.color}`]: {
    color: palette.primary[mode]
  },

  [`& .${classes.modalFormControl}`]: {
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      color: palette.color[mode],
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  },

  [`& .${classes.modalFormSelect}`]: {
    backgroundColor: palette.background[mode] + " !important",
    color: palette.color[mode] + " !important",
  },

  [`& .${classes.testSpeakerBtn}`]: {
    color: palette.primary[mode],
    textTransform: "none",
    borderRadius: "20px",
    paddingBottom: "2px",
    borderColor: palette.lightGray[mode],
  }
}));

export const UserDeviceManagement = ({
  open,
  locationUsername,
  handleClose,
  locationVolume,
  setLocationVolume,
}) => {
  const locationUsernameRef = useRef(locationUsername)
  const [selectedMic, setSelectedMic] = useState({})
  const [microphones, setMicrophones] = useState([])
  const [speakers, setSpeakers] = useState([])
  const [selectedSpeaker, setSelectedSpeaker] = useState({})
  const [selectedCamera, setSelectedCamera] = useState({})
  const [cameras, setCameras] = useState([])
  const [volume, setVolume] = useState(100)
  const [loading, setLoading] = useState(false)
  const [isManager, setIsManager] = useState(false)
  const debounceTimeoutRef = useRef(null);
  const vsDebounceTimeoutRef = useRef(null);
  const socket = useContext(SocketContext)

  useEffect(() => {
    if (socket) {
      socket.on('msg', ({ event, msg, from }) => { // from is displayName but we have username in this component
        if (event === 'list-devices') {
          let { cameras, microphones, speakers, volume, myUsername } = msg
          if (!myUsername || myUsername !== locationUsernameRef.current) {
            return;
          }
          let audiooutput = speakers.find((s) => s.selected === true)
          let audioinput = microphones.find((s) => s.selected === true)
          let camera = cameras.find((s) => s.selected === true)
          if (volume) setVolume(volume)
          setSelectedMic(audioinput)
          setSelectedSpeaker(audiooutput)
          setSelectedCamera(camera)
          setCameras(cameras)
          setMicrophones(microphones)
          setSpeakers(speakers)
          setLoading(false)
        }
      })
    }
    return (() => {
      if (socket) {
        socket.off('msg')
      }
    })
  }, [])

  useEffect(() => {
    locationUsernameRef.current = locationUsername
    if (open) {
      let manager = localStorage.getItem(Storages.IS_RECEPTION_MANAGER)
      manager ? setIsManager(true) : setIsManager(false)
      setLoading(true)
      if (!locationUsername) {
        setLoading(false)
        handleClose()
        return
      }
      sendRequestListDevices(locationUsername)
    }
  }, [open, locationUsername])

  const sendRequestListDevices = async (to) => {
    try {
      await apiServices.sendMessage({
        to: to,
        event: "list-devices"
      })
    } catch (error) {
      console.error(1330, error)
    }
  }

  const handleOnClickMicro = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-mic",
        to: locationUsername,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedMic(item)
      } else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's camera. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1332, error)
      toast.error("Failed to set location's microphone. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleOnClickSpeaker = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-speaker",
        to: locationUsername,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedSpeaker(item)
      }
      else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's speaker. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1333, error)
      toast.error("Failed to set location's speaker. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleOnClickCamera = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-camera",
        to: locationUsername,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedCamera(item)
      }
      else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's camera. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1334, error)
      toast.error("Failed to set location's camera. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleChangeVolume = async (e, vol) => {
    if(vol < 0) {
      vol = 0
    } else if(vol > 100) {
      vol = 100
    }
    setVolume(vol)
    clearTimeout(debounceTimeoutRef.current)
    debounceTimeoutRef.current = setTimeout(async () => {
      var res = await apiServices.sendMessage({
        event: "set-volume",
        to: locationUsernameRef.current,
        msg: vol
      })
      if (res.data.code !== 0) {
        toast.error("Failed to set location's volume. Error: " + res.data.msg, { autoClose: 5000 })
      }
    }, 500);
  }

  const testLocationSpeaker = async () => {
    try {
      var res = await apiServices.sendMessage({
        event: "play-test-sound",
        to: locationUsername,
      });
      if (res.data.code === 0) {
        toast.success("Sent!")
      } else {
        toast.error("Error while testing speaker: " + res.data.msg)
      }
    } catch (err) {
      console.error(err)
      toast.error("Error while testing speaker: " + err.message)
    }
  };

  const handleVsVolume = async (e, vol) => {
    try{
      if(vol < 0) {
        vol = 0
      } else if(vol > 100) {
        vol = 100
      }
      setLocationVolume(vol);
      clearTimeout(vsDebounceTimeoutRef.current)
      vsDebounceTimeoutRef.current = setTimeout(async () => {
        const res = await apiServices.sendMessage({
          event: "sendVolumeToLocation",
          to: locationUsernameRef.current,
          msg: {volume: vol}
        })
        if (res.data.code !== 0) {
          toast.error("Failed to set location's volume. Error: " + res.data.msg, { autoClose: 5000 })
        }
      }, 500);
    } catch (err) {
      console.error(3314, err);
      toast.error("Failed to set location's volume. Error: " + err.message, { autoClose: 5000 })
    }
  };

  return (
    <DraggableModal
      closeButton={true}
      onCloseButton={handleClose}
      header={<h5 className="mb-0">{locationUsername}'s {isManager ? "Device Management" : "Volume"}</h5>}
      body={<Root>
        {loading ?
          <span>Loading...</span> :
          <>
            {isManager && <>
              <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
                <InputLabel id="mic-label" >Microphone</InputLabel>
                <Select
                  label="Microphone"
                  labelId="mic-label"
                  value={selectedMic && selectedMic.deviceId || ""}
                  className={classes.modalFormSelect}
                >
                  {
                    Array.isArray(microphones) && !microphones[0] ? <MenuItem value="">No microphone found.</MenuItem> :
                      microphones.map((item, index) =>
                        <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickMicro(event, item)}>
                          {item.label}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" className={'mt-3 ' + classes.modalFormControl}>
                <InputLabel id="speaker-label" >Speaker</InputLabel>
                <Select
                  label="Speaker"
                  labelId="speaker-label"
                  value={selectedSpeaker && selectedSpeaker.deviceId || ""}
                  className={classes.modalFormSelect}
                >
                  {
                    Array.isArray(speakers) && !speakers[0] ? <MenuItem value="">No Speaker found.</MenuItem> :
                      speakers.map((item, index) =>
                        <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickSpeaker(event, item)}>
                          {item.label}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" className={'mt-3 mb-2 ' + classes.modalFormControl}>
                <InputLabel id="camera-label" >Camera</InputLabel>
                <Select
                  label='Camera'
                  labelId="camera-label"
                  value={selectedCamera && selectedCamera.deviceId || ""}
                  className={classes.modalFormSelect}
                >
                  {
                    Array.isArray(cameras) && !cameras[0] ? <MenuItem value="">No Camera found.</MenuItem> :
                      cameras.map((item, index) =>
                        <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickCamera(event, item)}>
                          {item.label}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </>}
            <div className={classes.modalFormControl}>
              <div className='d-flex justify-content-between'>
                <InputLabel className='mb-0 d-flex align-items-center'>Volume</InputLabel>
                {isManager && <>
                  <Button onClick={testLocationSpeaker} className={classes.testSpeakerBtn} variant='outlined'>
                    <b>Play test sound</b>
                  </Button>
                </>}
              </div>
              <div className='d-flex flex-row jitsi-content-between mt-2'>
                <label style={{fontSize: "13.5px", width: "35px"}} className='d-flex align-items-center mb-0 pr-2'>VS</label>
                <Stack className='w-100' spacing={2} direction="row" alignItems="center">
                  <VolumeDown onClick={(event) => handleVsVolume(event, locationVolume - 10)} style={{ cursor: "pointer" }} />
                  <Slider className={classes.color} value={locationVolume} onChange={handleVsVolume} valueLabelDisplay="auto" />
                  <VolumeUp onClick={(event) => handleVsVolume(event, locationVolume + 10)} style={{ cursor: "pointer" }} />
                </Stack>
              </div>
              {isManager && <>
                <div className='d-flex flex-row jitsi-content-between mt-2'>
                  <label style={{fontSize: "13.5px", width: "35px"}} className='d-flex align-items-center mb-0 pr-2'>OS</label>     
                  <Stack className='w-100' spacing={2} direction="row" alignItems="center">
                    <VolumeDown onClick={(event) => handleChangeVolume(event, volume - 10)} style={{ cursor: "pointer" }} />
                    <Slider className={classes.color} value={volume} onChange={handleChangeVolume} valueLabelDisplay="auto" />
                    <VolumeUp onClick={(event) => handleChangeVolume(event, volume + 10)} style={{ cursor: "pointer" }} />
                  </Stack>
                </div>
              </>}
            </div>
          </>
        }
      </Root>}
      show={open}
    />
  );
}