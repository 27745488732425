import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
  width: "400px",
  height: "90px",
  border: "2px solid purple",
  color: "purple",
  fontSize: "26px",
  fontWeight: "600",
  marginTop: "20px",
  borderRadius: "50px",
  overflow: "hidden",
  "&:hover": {
    borderColor: "purple",
  },
}));

const CircularProgress = styled("div")(({ progress }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "50px",
  zIndex: 0,
  background: `conic-gradient(#80008044 ${progress}%, transparent 0%)`,
  // transition: "background 0.1s linear",
  pointerEvents: "none",
}));

const HoldButton = ({ onHoldComplete }) => {
  const [progress, setProgress] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchend", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, [])

  const handleMouseDown = () => {
    let progressCount = 0;
    timerRef.current = setInterval(() => {
      progressCount += 10;
      setProgress((progressCount / 2000) * 100); 
      if (progressCount >= 2000) {
        clearInterval(timerRef.current);
        onHoldComplete();
      }
    }, 10);
  };

  const handleMouseUp = () => {
    clearInterval(timerRef.current);
    setProgress(0); // Reset progress if released early
  };

  return (
    <StyledButton
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      variant="outlined"
      disableRipple
    >
      {window.i18n.getString("lockScreenButtonText")}
      <CircularProgress progress={progress} />
    </StyledButton>
  );
};

export default HoldButton;
