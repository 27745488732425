import React, { Component } from 'react';
import './style.css';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MessageIcon from '@mui/icons-material/Message';
import { ThemeToggleContext } from '../../context/theme';
import {ButtonBase,Grid,Tooltip, useTheme} from "@mui/material";
import { useEffect,useState } from 'react';
import {useSelector } from 'react-redux';

export default ({user_name,user_status,location_name,handleOpenChat,display_Name})=>{
    const theme = useTheme();
    const [status,setStatus]=useState()
    const [color,setColor]=useState()
    const unRead = useSelector((state) => state.messages.unRead[user_name])

    useEffect(()=>{
        if (user_status === Constant.AWAY) {
          setStatus(Str.STR_AWAY);
          setColor(theme.palette.warning[theme.palette.mode])
        } else if (user_status === Constant.JOIN) {
          setStatus(Str.STR_ON_LINE);
          setColor(theme.palette.success[theme.palette.mode])
        } else if (user_status === Constant.CALL) {
          setStatus(Str.STR_ON_CALL);
          setColor(theme.palette.pink[theme.palette.mode])
        } else {
          setStatus(Str.STR_OFF_LINE);
          setColor(theme.palette.error[theme.palette.mode])
        }
    },[user_status])

    const handleOnClickChat=()=> {
       handleOpenChat(user_name,display_Name)
    }

    return (
      <div className='remote_reception_area'>
        <FiberManualRecordRoundedIcon className='mr-1' style={{color}} fontSize='small'/>
        <label className='d-flex align-items-center reception_name text-truncate'>
            {display_Name || user_name} 
            <span style={{fontSize: "14px"}}>&nbsp;
            {location_name ? 
                <span>(<PhoneInTalkIcon fontSize='inherit'/>&nbsp;{location_name})</span>: 
                ""
            }
            </span>
        </label>
        <ThemeToggleContext.Consumer>{({mode})=>{
            return (
                <Grid item  className='remote-user-view-grid'>
                    <Tooltip title={"Chat with " + (display_Name || user_name)} placement='left'>
                      <ButtonBase className='btn-chat' onClick={handleOnClickChat}>
                        <MessageIcon style={{color:(mode=='light'?'rgba(0, 0, 0, 0.87)':'#fff')}} />
                      </ButtonBase>
                    </Tooltip>
                    <div className='remote-user-view-number-unread'>
                        <small>{(unRead && unRead>0)?unRead :''}</small>
                    </div>
                </Grid>

            )
        }}
        </ThemeToggleContext.Consumer>
    </div>
    );
}