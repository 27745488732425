import React, { useEffect, useRef, useState } from "react";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./style.css";
import "./remotelocationvideoview.css";
import UnloginImage1 from '../../assets/images/unlogin_1.png';
import UnloginImage2 from '../../assets/images/unlogin_2.png';
import UnloginImage3 from '../../assets/images/unlogin_3.png';
import UnloginImage4 from '../../assets/images/unlogin_4.png';
import PreviewIcon from '../../assets/images/before_enable_btn.png';
import NextIcon from '../../assets/images/next_enable_btn.png';
import NightsStayOutlinedIcon from '@mui/icons-material/NightsStayOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import { sendReloadLocationToSocket } from '../../context/socket'
import { Box, Tooltip } from '@mui/material'
import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import { useSelector } from 'react-redux';
import RemoteLocationVideoView2 from "./RemoteLocationVideoView2";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ContextMenu from "../Reception/ContextMenu";
import { toast } from 'react-toastify';
import storages from "../../constants/storages";
import Constant from "../../constants/constant";

const ListLocations = React.memo((props) => {
  const { remoteLocations, allLocationData=[], localUserData, width, height,
    callState,locationAreaRef,handleDeviceManagement,handleOpenMessagingBoard,
    startMonitorCall,captureLocationCameraImage
   } = props;
  const LoginedImageList = [UnloginImage1, UnloginImage2, UnloginImage3, UnloginImage4];
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const receptionSidebar = useSelector((state) => state.receptionSidebar)
  const rightSidebar = useSelector((state) => state.rightSidebar)
  const [showContextMenu,setShowContextMenu]=useState(false)
  const contextMenuRef=useRef()
  const [contextLocation,setContextLocation]=useState(null)
  const [contextMenuDisabled,setContextMenuDisabled]=useState({
    reload:false,
    deviceManagement:true,
    shareScreen:true,
    monitoring:true,
    messagingBoard:false,
    takePicture:true,
    hangupHoldingCall:true,
  })
  const reloadLocation = (e, username) => {
    e.stopPropagation();
    let loc=remoteLocations.find((l)=>l.username===username)
    if(loc && loc.inCall) return
    sendReloadLocationToSocket(username)
  }


  useEffect(()=>{
    if(!showContextMenu ||  !contextLocation || !contextLocation.username) return
    let loc=remoteLocations.find((l)=>l.username===contextLocation.username)
    if(!loc) loc=allLocationData.find((l)=>l.username===contextLocation.username)
    if(!loc) {
      toast.warning("location not found")
      handleCloseContextMenu()
      return
    }
    var options=contextMenuDisabled
    if(loc.inCall){
      options.reload=true
      options.deviceManagement=true
      options.shareScreen=true
      options.monitoring=true
      options.takePicture=true
      options.messagingBoard=false
      if(localStorage.getItem(storages.IS_RECEPTION_MANAGER) && !callState ){
        options.monitoring=false
      }
      options.hangupHoldingCall=true
      if(loc.status ==Constant.HOLD){
        options.hangupHoldingCall=false
        options.monitoring=true
      }
  }
  else if(loc.audiotrack ||  loc.videotrack || loc.user){
      options.reload=false
      options.deviceManagement=false
      options.shareScreen=false
      options.messagingBoard=false
      options.takePicture=false
      options.monitoring=true
      options.hangupHoldingCall=true

  }
  else {
      options.reload=false
      options.deviceManagement=true
      options.shareScreen=true
      options.monitoring=true
      options.messagingBoard=true
      options.takePicture=true
      options.hangupHoldingCall=true
  }
  setContextMenuDisabled(options)
  setContextLocation(loc)
  },[remoteLocations,allLocationData])


  const rightClick=(e,username)=>{

    e.preventDefault()
    let x=e.pageX 
    let y=e.pageY 
    if(receptionSidebar && !rightSidebar) x=x-300 // sidebar width is 300px
    var listAreaWidth=locationAreaRef.current.clientWidth
    var listAreaHeight=locationAreaRef.current.clientHeight 

    const contextWidth= 200 // min width
    const contextHeight= 320
    // diff height of scroll with client height
    let scrollHeight=locationAreaRef.current.scrollHeight
    if(scrollHeight> listAreaHeight) {
      y+= scrollHeight-listAreaHeight
    }
    if(listAreaWidth < (e.pageX + contextWidth)) contextMenuRef.current.style.left=x-contextWidth+"px"
    else contextMenuRef.current.style.left=x+"px"
    if(listAreaHeight < (e.pageY + contextHeight) ) contextMenuRef.current.style.top=y-contextHeight+"px"
    
    else contextMenuRef.current.style.top=y+"px"
    let loc=remoteLocations.find((l)=>l.username===username)
    if(!loc) loc=allLocationData.find((l)=>l.username===username)
    if(!loc) return toast.warning("Location not found")
    var options=contextMenuDisabled
  
    if(loc.inCall){
      options.reload=true
      options.deviceManagement=true
      options.shareScreen=true
      options.messagingBoard=false
      options.monitoring=true
      if(localStorage.getItem(storages.IS_RECEPTION_MANAGER) && !callState ){
        options.monitoring=false
      }
      options.hangupHoldingCall=true
      if(loc.status===Constant.HOLD){
        options.hangupHoldingCall=false
        options.monitoring=true
      }
  }
  else if(loc.audiotrack ||  loc.videotrack || loc.user){
      options.reload=false
      options.deviceManagement=false
      options.shareScreen=false
      options.monitoring=true
      options.messagingBoard=false
      options.takePicture=false
      options.hangupHoldingCall=true
  }
  else {
      options.reload=false
      options.deviceManagement=true
      options.shareScreen=true
      options.monitoring=true
      options.messagingBoard=true
      options.takePicture=true
      options.hangupHoldingCall=true

  }
    setContextMenuDisabled(options)
    setContextLocation(loc)
    setShowContextMenu(true)
  }

  const handleCloseContextMenu=()=>{
    setContextLocation(null)
    setShowContextMenu(false)
  }

  function getUnLogedImage(logo, index) {
    let ret = "";

    if (logo === "" || logo === null) {
      ret = LoginedImageList[index % 4];
    } else if (logo.startsWith("http://") || logo.startsWith("https://")) {
      ret = logo;
    } else {
      ret = process.env.REACT_APP_UPLOAD_URL + logo
    }

    return ret;
  }

  return (
    <>
      {allLocationData.map((oneLocation, oneLocationIndex) => {
        var locUsername=oneLocation.username
        const isSec = !oneLocation.isPrimary;
        if (isSec && !showSecondaryLocations) return;

        var onlineIndex = remoteLocations.findIndex((rl) => rl.username == oneLocation.username)
        if (onlineIndex != -1 && oneLocation.isSleep != 1 && oneLocation.isOnline != 0 && !oneLocation.locationIsAway) {
          var online = remoteLocations[onlineIndex]
          if (online.videotrack === null) {
            return (
              <Box className={'location_box_root' + (isSec ? ' location_box_secondary' : "")}
                onContextMenu={(e)=>{
                  e.preventDefault()
                  rightClick(e,locUsername)
                }}
                style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}
                onClick={() => { props.handleItemSleep(oneLocationIndex) }}>
                <div className='location_box_img'
                  style={{
                    display: "flex",
                    backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                    backgroundPosition: "center", backgroundSize: "cover"
                  }}
                  alt={`unloged_img_${oneLocationIndex}`} >

                </div>
                <Tooltip title='No video track'>
                  <div className='location_box_name'>
                    <VideocamOffOutlinedIcon />
                    <label style={{ margin: '0 5px' }}>
                      {oneLocation.locationname}
                      {isSec &&
                        <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                      }
                    </label>
                  </div>
                </Tooltip>
              </Box>
            )
          } else {
            return (
              <div key={oneLocationIndex} className={'location_box_root' + (isSec ? "  location_box_secondary" : "")}>
                <RemoteLocationVideoView2
                  handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
                  handleItemCall={() => props.handleItemCall()}
                  rightClick={(e,username)=>rightClick(e,username)}
                  track={online.videotrack}
                  othertrack={online.othertrack}
                  video_tag_id={online.videotrack.getParticipantId() + 'location' + online.videotrack.getType()}
                  locationName={online.locationname}
                  index={onlineIndex}
                  calltag={online.calltag}
                  calltagColor={online.calltagColor}
                  callState={callState}
                  status={online.status}
                  localUserData={localUserData}
                  username={online.username}
                  width={width}
                  height={height}
                  id={online.id}
                  holdTimer={online.holdTimer}
                  currentLangCode={online.currentLangCode}
                  inCall={online.inCall}
                  callBy={online.callBy}
                />
              </div>
            )
          }
        } else if (oneLocation.isSleep == 1 && oneLocation.isOnline == 1 && !oneLocation.locationIsAway) {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}
              onContextMenu={(e)=>{
                e.preventDefault()
                rightClick(e,locUsername)
              }}
              onClick={() => { props.handleItemSleep(oneLocationIndex) }}>
              <div className='location_box_img'
                style={{
                  display: "flex",
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`} >
              </div>
              <div className='location_box_name' title="This location is on sleep screen">
                <NightsStayOutlinedIcon style={{ marginLeft: "1px" }} />
                <label style={{ margin: '0 5px' }}>
                  {oneLocation.locationname}
                  {isSec &&
                    <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </label>
              </div>
            </Box>
          )
        } else if (!oneLocation.isOnline) {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              style={{ width, height }} key={oneLocationIndex}>
              <div className='location_box_img'
                style={{
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`}>
              </div>
              <div className='location_box_name' title="This location is offline">
                <PowerOffOutlinedIcon />
                <label style={{ margin: '0 5px' }}>
                  {oneLocation.locationname}
                  {isSec &&
                    <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </label>
              </div>
            </Box>
          )
        } else if(oneLocation.locationIsAway) {
          return <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
            style={{ width, height }} key={oneLocationIndex}>
            <div className='location_box_img'
              style={{
                backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                backgroundPosition: "center", backgroundSize: "cover"
              }}
              alt={`unloged_img_${oneLocationIndex}`}>
            </div>
            <div className='location_box_name' title="This location is on away screen">
              <PauseCircleOutlineIcon/>
              <label style={{ margin: '0 5px' }}>
                {oneLocation.locationname} <small>(Away)</small>
                {isSec &&
                  <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                }
              </label>
            </div>
          </Box>
        } else {
          return (
            <Box className={'location_box_root' + (isSec ? "  location_box_secondary" : "")} 
              onContextMenu={(e)=>{
                e.preventDefault()
                rightClick(e,locUsername)
              }}
              style={{ cursor: 'pointer', width, height }} key={oneLocationIndex}>
              <div className='location_box_img'
                style={{
                  display: "flex",
                  backgroundImage: `url(${getUnLogedImage(oneLocation.logo, oneLocationIndex)})`,
                  backgroundPosition: "center", backgroundSize: "cover"
                }}
                alt={`unloged_img_${oneLocationIndex}`}>
              </div>
              <Tooltip title='Waiting'>
                <div className='location_box_name'>
                  <HourglassEmptyOutlinedIcon />
                  <label style={{ margin: '0 5px' }}>
                    {oneLocation.locationname}<small> (waiting...)</small>
                    {isSec &&
                      <sup style={{ color: "#f3c200" }} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </label>
                </div>
              </Tooltip>
            </Box>
          )
        }
      })}
      <ContextMenu
        display={showContextMenu} 
        contextMenuRef={contextMenuRef} 
        handleCloseContextMenu={handleCloseContextMenu} 
        location={contextLocation}
        reloadLocation={(event,username)=>reloadLocation(event,username)}
        handleDeviceManagement={handleDeviceManagement}
        handleOpenMessagingBoard={handleOpenMessagingBoard}
        reload={contextMenuDisabled.reload}
        deviceManagement={contextMenuDisabled.deviceManagement}
        shareScreen={contextMenuDisabled.shareScreen}
        messagingBoard={contextMenuDisabled.messagingBoard}
        monitoring={contextMenuDisabled.monitoring}
        takePicture={contextMenuDisabled.takePicture}
        startMonitorCall={(username)=>startMonitorCall(username)}
        callState={callState}
        captureLocationCameraImage={captureLocationCameraImage}
        hangupHoldingCall={contextMenuDisabled.hangupHoldingCall}
        startHangupHoldingCall={props.handleClickHoldLocation}
        />
    </>
  );
});

export default ListLocations;
