import React from "react";
import { styled } from '@mui/material/styles';
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useState } from "react";
import String from '../../constants/string';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
const Root = styled('div')({
  backgroundColor:"#404040",
  borderRadius:"5px",
  padding:"5px 10px 5px 10px",
  color:"white",
  minWidth:"5vw",
  marginInline: "auto",
  marginTop: "10px"
});

export default function LocationNameBox ({name, id, locationConnectionIssue}){
    const tracksMuted = useSelector((state) => state.tracksMuted)
    const [tracksMutedContent,setTracksMutedContent] = useState([])

    useEffect(()=>{
        if(id){
            var content=[]
            let devices=tracksMuted[id]
            if(devices && devices[String.STR_VIDEO] ) {
                content.push(<VideocamOffOutlinedIcon />)
            }
            if(devices && devices[String.STR_AUDIO]) {
                content.push(<MicOffOutlinedIcon/>)
            }
            setTracksMutedContent(content)
        }
    },[tracksMuted,id])

    return (
        <>
            {name?
                <Root>
                    <h5 className="mb-0 text-center">
                      {tracksMutedContent} 
                      {name} 
                      {locationConnectionIssue && <span className="text-warning"><br/>Connection issues</span>}
                    </h5>
                </Root>
                :null
            }
        </>
    );
}
 
