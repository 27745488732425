import { createSlice } from '@reduxjs/toolkit';


export const CustomURLs=createSlice({
    name:"customURL",
    initialState:[],
    reducers:{
        setCustomURLs:(state, action)=>{
            state=action.payload

            return state
        }
    }
})

export const { setCustomURLs} = CustomURLs.actions

export default CustomURLs.reducer