import React, { useEffect, useState ,useMemo, useRef} from 'react';
import { styled } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';
import LocationArea from '../../Components/Location/LocationArea';
import UserArea from '../../Components/User/UserArea';
import AdminService from '../../services/api';
import { toast } from 'react-toastify';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import Storages from '../../constants/storages';
import './style.css';
import './bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMics } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCameras } from '../../redux/cameraSlice';
import {setShowSecondaryLocations} from '../../redux/showSecondaryLocations';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import {ThemeToggleContext} from '../../context/theme'
import RequestPaymentModal from '../../Components/Modal/RequestPayment';
import PrintRequestModal from '../../Components/Modal/PrintRequest';
import PrintFileRequestModal from '../../Components/Modal/PrintFileRequest';
import ScanRequestModal from '../../Components/Modal/ScanRequest';
import ReactLoading from 'react-loading';
import LocationNameBox from'../../Components/Location/LocationNameBox'

import {GalleryModal} from '../../Components/Modal/Gallery'
import CardDetectionRequest from '../../Components/Modal/CardDetectionRequest';
import syncSharedFiles from '../../services/syncSharedFiles';
import {setUnReadMessages} from '../../redux/messages'
import { createVirtualBackgroundEffect } from '../../services/virtual-background';
import Connection from '../../Components/User/Connection';
import {trackMuteChange} from '../../redux/tracksMuted';
import { setRemoteStat, clearRemotesID, setAllRemoteStats } from '../../redux/remoteStats';
import LocationCallTag from '../../Components/Location/LocationCallTag';
import { ReportIssue } from '../../Components/Modal/ReportIssue';
import { addSpeaker, selectSpeaker } from '../../redux/speaker';
import { SettingsModal } from '../../Components/Reception/Setting';
import { UserDeviceManagement } from '../../Components/User/UserDeviceManagement';
import tokenService from '../../services/tokenService';
import constant from '../../constants/constant';
import jitsiConnectionConfig from '../../constants/jitsiConnectionConfig';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import usePreventFileDrop from '../../services/usePreventFileDrop';
import useBeforeUnload from '../../services/useBeforeUnload';
import { MessagingBoardModal } from '../../Components/Modal/MessagingBoards';
import ReceptionFooter from '../../Components/Reception/ReceptionFooter';

var updtLocActvIntv
let holdTimerInterval = null;
let checkCallStateInterval = null;
let getStatsInterval = null;
let virtualBackgroundEffect;
const Doctor = (props) => {
    const dispatch = useDispatch();
    const [userStatus, setUserStatus] = useState(Constant.LOGOUT);
    const [localUserData, setLocalUserData] = useState([]);
    const [receptionData, setReceptionData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    // const [allLocationData, setAllLocationData] = useState([]);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [locationName, setLocationName] = useState("");
    const [locationVideoTrack, setLocationVideoTrack] = useState(null);
    const [locationAudioTrack, setLocationAudioTrack] = useState(null);
    const [callState, setCallState] = useState(false);
    const callStateRef=useRef(callState)
    const [playState, setPlayState] = useState(false);
    const [glowState, setGlowState] = useState(false);
    const [camera, setCamera] = useState(true); // we have to start camera unmute, beacuse of jitsi bug.
    const [mic, setMic] = useState(true);
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const connections = useSelector((state) => state.connections)
    const [joinState, setJoinState] = useState(false);
    const [allLocations, setAllLocations]=useState([])
    const [localConnectionQuality, setLocalConnectionQuality] = useState({jvbRTT: 0, connectionQuality: 0})
    const allLocationsRef=React.useRef(allLocations)
    const {mode}=useContext(ThemeToggleContext)
    const [locationID, _setLocationID]=useState(0) // jitsi participant id
    const locationIDRef = useRef(locationID)
    const setLocationID = (newVal) => {
      locationIDRef.current = newVal;
      _setLocationID(newVal)
    }
    const _setAllLocations = data => {
        allLocationsRef.current = data;
        setAllLocations(data);
    };
    // const allLocations = useSelector((state) => state.locations);
    const [printFileModalShow, setPrintFileModalShow] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const isSignalAccept = React.useRef(false);
    const isLogout = React.useRef(false);
    const room = React.useRef(null);
    const locationNum = React.useRef(-1);
    /** joined users */
    let listReceptionData = [];
    const listReceptionDataRef = useRef([]);
    let listLocationData = [];
    let listRemoteUsers = [];
    const listRemoteUsersRef = useRef([]);
    /** unjoined location users */
    let listAllLocationUsers = [];
    let localTracks = [];
    const connection = React.useRef(null);
    const volumeWarningShowed = React.useRef(false);
    let lastTime;
    let isJoined = false;

    const [locationEngaged, _setLocationEngaged] = useState(null)
    const [payReqModal, setPayReqModal] = useState(false);
    /* Scan */
    const [openScanRequestModal,setOpenScanRequestModal]=useState(false)
    
    /*sleep mode */
    const locationEngagedRef = React.useRef(locationEngaged); // to access in event listener
    const socket = useContext(SocketContext)
    const setLocationEngaged = data => {
        locationEngagedRef.current = data;
        _setLocationEngaged(data);
        if (data) stopMonitorCall();
    };
    /* Scan images editor*/
    const [showGalleryModal,setShowGalleryModal]=useState(false)
    const [defaultBatch,setDefaultBatch]=useState()
    const [openCardDetection,setOpenCardDetection]=useState(false)
    const nodeRef = React.useRef(null);
    const [timeOutForCamera,setTimeOutForCamera]=useState()
    const [locationVolume, setLocationVolume] = useState(0)
    const localVideoTrackRef= useRef(null)
    const localAudioTrackRef = useRef(null);
    const captureImageToastID = useRef(null)
    const [issueReporterOpen, setIssueReporterOpen] = useState(false)
    const [showModalSetting,setShowModalSetting]=useState(false)
    const [openDeviceManagement,setOpenDeviceManagement]=useState(false)
    const [callLoading,setCallLoading]=useState(false)
    const callLoadingTimeoutRef=useRef()
    const monitorCallLocationRef = useRef(false);
    const monitorCallReceptionRef = useRef(false);
    const [monitorCallStatus, _setMonitorCallStatus] = useState(false);
    const [monitorCallHold,setMonitorCallHold]=useState(false)
    const monitorCallStatusRef = useRef(false);
    const setMonitorCallStatus = (newVal) => {
      monitorCallStatusRef.current = newVal;
      if (newVal) {
        setMonitorCallHold(false);
        handleCancelEngagedLocation(locationNum.current);
      }
      _setMonitorCallStatus(newVal)
    }
    const [locationUsernameForDeviceManager, setLocationUsernameForDeviceManager]=useState()
    const LocationCallTagMemo=useMemo(()=>LocationCallTag,[callState,locationData,locationID])
    const UserDeviceManagementMemo=useMemo(()=>UserDeviceManagement, [openDeviceManagement, locationUsernameForDeviceManager])
    const SettingsModalMemo=useMemo(()=>SettingsModal,[showModalSetting,callState])
    const ReportIssueMemo=useMemo(()=>ReportIssue,[  issueReporterOpen, allLocations, locationEngaged])
    const callToken=useRef({})
    const myUsername = useRef(localStorage.getItem(Storages.LOCAL_LOGINED_USER))
    const [showModalMBoard,setShowModalMBoard]=useState(false)
    const waitingForHangupLocationUsername = useRef("");
    const waitedLocationHangedup = useRef(false);
    const cameraErrorShown = useRef(false);
    const microphoneErrorShown = useRef(false);
    const lastLocationResToCallState = useRef(null);
    const [locationConnectionIssue, setLocationConnectionIssue] = useState(false);
    const [locationMessagingBoard,setLocationMessagingBoard]=useState()
    const remoteStats = useSelector((state) => state.remoteStats);
    const remoteStatsRef = useRef(remoteStats);
    const rightSidebar = useSelector((state) => state.rightSidebar);
    const rightSidebarRef = useRef(rightSidebar);

    // To prevent the default behavior of the browser when files are dropped onto the page
    usePreventFileDrop();

    // To show a warning when user is trying to leave the page.
    useBeforeUnload();

    /**
     * Function that check sleep and refresh on waking up.
     * 
     */
    function checkSleeping() {
        let currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + Constant.SLEEP_INTERVAL * 2)) {  // ignore small delays
            setTimeout(function () {
                //enter code here, it will run after wake up
                if(window.electron) {
                  window.electron.reload();
                } else {
                  window.location.replaceWithoutWarn("#/")
                }
            }, Constant.TEN_SEC_INTERVAL);
        }
        lastTime = currentTime;
    }
    
    useEffect(() => {
        // in api, on connection socket checked if reception already connected in another session.
        socket && socket.disconnect()
        socket && socket.connect()
        console.alert(7781, "Doctor useEffect[]")

        getListLocations()
        localStorage.removeItem("reload_lock")
        dispatch(setShowSecondaryLocations(Boolean(localStorage.getItem("showSecondaryLocations"))))

        // updateUserStatus(Constant.LOGIN);
        socketOn()
        let jitsiUrl;
        if (window.JitsiMeetJS) {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
              console.alert(1390, "!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices");
              setCamera(false)
              setMic(false)
              toast.error(`No media device found. Please reload. 
                If this issue persists, please contact customer support.
              `, {autoClose: 10000})
              return;
            }
            //This function is used by jitsi meet
            //https://www.whatismyip.com/webrtc/
            if(typeof RTCPeerConnection === "undefined") {
              console.alert(1386, "!RTCPeerConnection");
              window.confirmAsync.show(
                <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Error</h6>, 
                <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
                  WebRTC is not enabled in your browser. VS needs this feature to be able to work. 
                  Please contact customer support.
                </span>, 
                [
                  { value: 0, color: "primary", text: "Reload", close: 1 },
                ]
              ).then(() => window.location.replaceWithoutWarn("#/"))
              return;
            }

            getAllReceptionList();

            window.$ = $
            window.jQuery = $

            const initOptions = {
              disableAudioLevels: true,
              enableAnalyticsLogging: false
            } 
            window.JitsiMeetJS.init(initOptions);
            window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.ERROR);

            const token = localStorage.getItem("jitsiJwt")
            let jitsiConfig;
            if(localStorage.getItem("isJaas")) {
              jitsiConfig = jitsiConnectionConfig("jaas")
            } else {
              jitsiConfig = jitsiConnectionConfig()
            }

            jitsiUrl = jitsiConfig.hosts.domain
            localStorage.setItem("finalJitsiUrl", jitsiUrl)
            connection.current = new window.JitsiMeetJS.JitsiConnection(null, token, jitsiConfig);

            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);

            connection.current.addEventListener( window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED , (err)=>console.error(3130,err));
            connection.current.addEventListener( window.JitsiMeetJS.errors.conference.RESERVATION_ERROR  , (err)=>console.error(3133,err));
            connection.current.addEventListener( window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE   , (err)=>console.error(3132,err));
            connection.current.addEventListener( window.JitsiMeetJS.errors.track.NOT_FOUND, (err)=>console.error(3134,err));
            connection.current.addEventListener( window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND , (err)=>console.error(3135,err));
            
            connection.current.connect();

            dispatch(deleteMics());
            dispatch(deleteCameras());
            if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
                window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                    let cameraIndex = -1, micIndex = -1, spIndex=-1;
                    const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                    if(!videoInputDevices[0]) {
                      setCamera(false)
                      toast.error(`No camera found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
                    }
                    const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                    if(!audioInputDevices[0]) {
                      setMic(false)
                      toast.error(`No microphone found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
                    }
                    const audioOutpuDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
                    if(!audioOutpuDevices[0]) {
                      toast.error(`No speaker found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
                    }
                    var cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID);
                    var micDeviceId = localStorage.getItem(Storages.LOCAL_MIC_ID);
                    var speakerDeviceId = localStorage.getItem(Storages.LOCAL_SPEAKER_ID);
                    audioInputDevices.forEach((item, index) => {
                        if(item.deviceId == "communications") {
                            return;
                        }
                        let label = item.label;
                        let mic = { id: index, deviceId: item.deviceId, label, selected: false }
                        if (item.deviceId === micDeviceId) {
                            micIndex = index;
                            mic.selected = true;
                        }
                        dispatch(addMic(mic));
                    })

                    if (micIndex === -1 && audioInputDevices.length) {
                        micDeviceId = audioInputDevices[0].deviceId;
                        // localStorage.setItem(Storages.LOCAL_MIC_ID,micDeviceId)
                        dispatch(selectMic({ deviceId: micDeviceId }));
                    }

                    window.JitsiMeetJS.createLocalTracks({
                        devices: [Str.STR_AUDIO],
                        micDeviceId: micDeviceId
                    })
                    .then(onLocalTracks)
                    .catch(error => {
                        handleErrorTrack(error,1498,'microphone')
                        // console.error(1498, error)
                        setMic(false)
                        // toast.error(`There is an issue with audio track. Please reload. 
                        //   If this issue persists, please contact customer support.
                        // `)
                    });
                    videoInputDevices.forEach((item, index) => {
                        let camera = { id: index, deviceId: item.deviceId, label: item.label, selected: false }
                        if (item.deviceId === cameraDeviceId) {
                            cameraIndex = index;
                            camera.selected = true;
                        }
                        dispatch(addCamera(camera));
                    })

                    if (cameraIndex === -1 && videoInputDevices.length) {
                        cameraDeviceId = videoInputDevices[0].deviceId;
                        // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
                        dispatch(selectCamera({ index: 0 }));
                    }

                    window.JitsiMeetJS.createLocalTracks({
                        devices: [Str.STR_VIDEO],
                        cameraDeviceId: cameraDeviceId
                    })
                    .then(onLocalTracks)
                    .catch(error =>{
                      handleErrorTrack(error,1495,'camera')
                        // console.error(1495, error)
                        setCamera(false)
                        // toast.error(`There is an issue with video track. Please reload. 
                        //   If this issue persists, please contact customer support.
                        // `)
                    });
                  // Speaker
                  audioOutpuDevices.forEach((item, index) => {
                  if(item.deviceId == "communications") {
                      return;
                  }
                  let label = item.label;
                  let sp = { id: index, deviceId: item.deviceId, label, selected: false }
                  if (sp.deviceId === speakerDeviceId) {
                      spIndex = index;
                      sp.selected = true;
                  }
                  dispatch(addSpeaker(sp));   
                })
                if (spIndex === -1 && audioOutpuDevices.length) {
                  speakerDeviceId = audioOutpuDevices[0].deviceId;
                  localStorage.setItem(Storages.LOCAL_SPEAKER_ID,speakerDeviceId)
                  dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
                }
                if(speakerDeviceId) {
                  if(document.getElementById('locationAudio')) {
                    document.getElementById('locationAudio').setSinkId(speakerDeviceId)
                  }
                  if(document.getElementById('mainRAudio')) {
                    document.getElementById('mainRAudio').setSinkId(speakerDeviceId)
                  }
                  if(document.getElementById('alarmAudio'))  {
                    document.getElementById('alarmAudio').setSinkId(speakerDeviceId)
                  }
                }
              });               
            }
            // A hack for jitsi mute and unmute bug,
            // we have to start camera unmute, beacuse of jitsi bug, and then mute it after 10 sec to reduce BW.
            // https://community.jitsi.org/t/lib-jitsi-meet-mute-then-unmute-leads-to-frozen-remote-video-track/78713
            var timeout=setTimeout(() => {
                setCallStateAndLocalVideo(false)
            }, 10000)
            setTimeOutForCamera(timeout)

            holdTimerInterval = setInterval(() => {
              let listData = [...listLocationData];
              let holdFound = false;
              listData.forEach((remoteUser) => {
                if (remoteUser.status === Constant.HOLD) {
                  holdFound = true;
                  remoteUser.holdTimer++
                }
              });
              if(holdFound) {
                listLocationData = [...listData];
                setLocationData(listData);
              }
            }, 1000)
        }

        // Commented due to reloads the page when waiting for scanned images
        // We do not know what's the usage
        //// check sleep computer timer
        // lastTime = (new Date()).getTime();
        // let intervalId = setInterval(checkSleeping, Constant.SLEEP_INTERVAL)

        // when electron send user-leave, this function call
        if (window.electron) {
          window.electron.userLeave(async () => {
            try{
              setShowLoading(true)
              // await window.electron.auth0Logout()
              var token = tokenService.get();
              if (token) {
                await AdminService.userLeave({
                  username: myUsername.current,
                })
                await unload(false)
                window.electron.allWindowsClose()
              } else {
                await unload(false)
                window.electron.allWindowsClose()
              }
            } catch (err) {
              setShowLoading(false)
              console.error(1494, err)
              window.confirmAsync.show(
                <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Error</h6>, 
                <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
                  There was an error when trying to exit. Do you want to exit anyway?
                </span>, 
                [
                  { value: 1, color: "primary", text: "Yes", close: 1 },
                  { value: 0, color: "default", text: "No", close: 1 }
                ]
              ).then((value) => {
                if(value === 1) {
                  window.electron.allWindowsClose()
                }
              })
            }
          });
          window.electron.toggleDarkMode(mode)
          window.electron.startTracerouteInterval(process.env.REACT_APP_API_URL, jitsiUrl)
        }

        return (() => {
          // clearInterval(intervalId);
            if(!window.electron) unload()
            if(updtLocActvIntv) clearInterval(updtLocActvIntv)
            if(getStatsInterval) clearInterval(getStatsInterval)
            clearInterval(holdTimerInterval)

            localVideoTrack && localVideoTrack.detach($(`#mainRVideo`)[0]);
            localAudioTrack && localAudioTrack.detach($(`#mainRAudio`)[0]);
            disconnectSocket()
        })
    }, []);

    const disconnectSocket=()=>{
      if(socket){
        socket.off('setLocationLabel')
        socket.off('msg')
        socket.off('join')
        socket.off('leave')
        socket.off("unSleep")
        socket.off("sleep")
        socket.off("locations")
        socket.off("reloadSharedFiles")
        socket.off("receptionStatus")
        socket.off("manager")
        socket.off("callHoldStatus");
        socket.disconnect()
    }
  }

    useEffect(() => { 
      if(localVideoTrack) {
        if(camera) {
          localVideoTrack.attach($(`#mainRVideo`)[0]);
        } else {
          localVideoTrack.detach($(`#mainRVideo`)[0]);
        }
      }
    }, [camera, localVideoTrack])

    useEffect(() => {
      remoteStatsRef.current = remoteStats;
    }, [remoteStats]);

    useEffect(() => {
      rightSidebarRef.current = rightSidebar;
      setReceiverCons()
    }, [rightSidebar]);

    useEffect(() => {
      setReceiverCons();
    }, [locationVideoTrack, monitorCallStatus])
    
    const getListLocations=async ()=>{
      try{
        var result = await AdminService.getListLocations()
        if(result.data.code === 0){
          if(result.data.data && result.data.data[0]){
            _setAllLocations(result.data.data)
          }
        }
        else toast.error(result.msg,5000)
      } catch (err) {
        console.error(err)
        toast.error(err.message, 10 * 1000)
      }
    }
    function socketOn() {
        if (socket) {
            socket.on('setLocationLabel', ({ username, label }) => {
                var data = [...listLocationData]
                if (data) {
                    let locations = data.map((loc) => {
                        if (loc.username == username) {
                            loc.calltag = label
                        }
                        return loc
                    })
                    setLocationData(locations)
                }
            })
            socket.on('msg', ({event, msg, from})=>{
              if(event ==='scan-result-is-ready'){
                const batchID=msg.batchID
                setDefaultBatch(batchID)
                toast.success(
                  <div>Image saved successfully. <a href='javascript:void(0)' onClick={handleOpenGallery}>Open in gallery</a></div>, 
                  {closeOnClick: true, autoClose: 5000}
                );
                // handleCloseScanRequestModal()
                closeCardDetection()
              } else if (event === "sendVolumeToReception") {
                setLocationVolume(msg.volume || 100);
                if(msg.volume < 20 && !volumeWarningShowed.current) {
                  toast.warning("Location's audio volume is too low. You may consider increasing it.", {autoClose: 10000})
                  volumeWarningShowed.current = true;
                }
              } else if(event === 'capture-image-res'){
                toast.dismiss(captureImageToastID.current);
                if(msg.code === 0) {
                  setDefaultBatch(msg.data.batchID)
                  toast.success(
                    <div>Image saved successfully. <a href='javascript:void(0)' onClick={handleOpenGallery}>Open in gallery</a></div>, 
                    {closeOnClick: true, autoClose: 5000}
                  );
                } else {
                  toast.error(msg.msg, {autoClose: 10000})
                }
              }
            })

            socket.on("location-lang-changed", ({locationUsername, newLang}) => {
              let listData = [...listLocationData];
              listData.forEach((remoteUser) => {
                  if (remoteUser.username === locationUsername) {
                      remoteUser.currentLangCode = newLang;
                  }
              });
              listLocationData = [...listData];
              setLocationData(listData);
            })
            
            socket.on('locations',({locations})=>{
              console.alert(1391, "socket.on('locations', locations:", locations.length)
              _setAllLocations(locations)
            })
            
            socket.on('join', (username)=>{ // when location connect to socket api
              console.alert(1392, "socket.on('join', username: ", username)
                let locations=[...allLocationsRef.current]
                locations=locations.map((loc)=>{
                  if(loc.username == username){
                    return {...loc, isOnline:1}
                  }
                  return loc
                })
              _setAllLocations(locations)
              if(locationEngagedRef.current === username) {
                setLocationConnectionIssue(false);
              }
            })

            socket.on('leave', (username)=>{ // when location leave from socket api
              console.alert(1393, "socket.on('leave', username: ", username)
              let locations=[...allLocationsRef.current]
              locations=locations.map((loc)=>{
                if(loc.username==username){
                  return {...loc, isOnline:0}
                }
                return loc
              })
              _setAllLocations(locations)
              if(locationEngagedRef.current === username) {
                setCallLoading(false)
                clearTimeout(callLoadingTimeoutRef.current)
                callLoadingTimeoutRef.current=null

                setLocationConnectionIssue(true);

                // currentCallEnd()
                // toast.warning("The call ended as location disconnected")
              }
            })

            socket.on('sleep',({username})=>{
              let locations=[...allLocationsRef.current]
              var isChange=false
              locations=locations.map((loc)=>{
                  if(loc.username==username){
                      if(loc.isSleep == 0) isChange=true
                      return {...loc,isSleep:1}
                  }
                  return loc
              })
              if(isChange) _setAllLocations(locations)
            })
            socket.on('unSleep',({username})=>{
              let locations=[...allLocationsRef.current]
              var isChange=false
              locations=locations.map((loc)=>{
                  if(loc.username==username){
                      if(loc.isSleep ==1) isChange=true
                      return {...loc,isSleep:0}
                  }
                  return loc
              })
              if(isChange) _setAllLocations(locations)
            })
            socket.on('reloadSharedFiles',()=>{
              if(window.electron) {
                syncSharedFiles().then((data) => {
                  if(data.code !== 0) {
                    console.error(1492, data)
                    toast.error(data.msg, {autoClose: 10000})
                  }
                })
              }
            })

            socket.on("receptionStatus", ({id, username, newStatus, newOtherpartid}) => {
              try{
                let listData = [...listReceptionDataRef.current];
                let isFindReception = false;
                listData.forEach((user, index) => {
                    if (user.username === username) {
                        listData[index].status = newStatus;
                        listData[index].otherpartid = newOtherpartid;
                        isFindReception = true;
                        return;
                    }
                });
    
                if (isFindReception) {
                    listReceptionDataRef.current = [...listData];
                    sortByUserStatus(); // setReceptionData is in this method
                } else {
                    getAllReceptionList();
                }
                return;
              } catch (err) {
                console.error(1092, err)
              }
            })
            socket.on("showHangupMessage", ({locationUsername, receptionName}) => {
              toast.info(
                <span>Your held call with <i>{locationUsername}</i> has been picked up by <i>{receptionName}</i>.</span>, 
                {autoClose: false, closeOnClick: true}
              )
            })
            socket.on("manager",({isManager})=>{
              if(isManager) localStorage.setItem(Storages.IS_RECEPTION_MANAGER,isManager)
              else localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
            })
            socket.on("locationIsAway", ({id, value}) => {
              let locations = [...allLocationsRef.current]
              let location = locations.find(item => item.userID == id);
              if(location && location.locationIsAway !== value) {
                location.locationIsAway = value
                _setAllLocations(locations);
              }
            })
            socket.on("callHoldStatus", (data)=>{
              if(data.hold) {
                callHoldOn(data.locationName);
              } else {
                callHoldOff(data.locationName);
              }
            })
        }
    }

    async function getAllReceptionList() {
      try{
        const res = await AdminService.getAllReceptionList();
        if(res.data.code !== 0) {
          console.error(1499, res.data)
          toast.error(res.data.msg, {autoClose: 10000});
          return;
        }

        listReceptionDataRef.current = res.data.data;
        setReceptionData(res.data.data);
        dispatch(setUnReadMessages(res.data.data))
      } catch (err) {
        console.error(1491, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const onConnectionSuccess = () => {
        const roomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME)
        console.alert(1394, "onConnectionSuccess, roomName:", roomName)
        const confOptions = {
          openBridgeChannel: true,
          // enabling p2p will cause issues when setting lastN to 0 or 1
          p2p:{enabled:false},
          disableAudioLevels: true,
        };
        room.current = connection.current.initJitsiConference(roomName, confOptions);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, onTrackAdded);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_FAILED, (e)=>{
          console.error(1490, "events.conference.CONNECTION_FAILED", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e)=>{
          console.error(1489, "events.conference.CONFERENCE_DESTROYED", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e)=>{
          console.error(1488, "events.conference.CONNECTION_ERROR", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e)=>{
          console.error(1487, "window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED",e )
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e)=>{
          console.error(1486, "window.JitsiMeetJS.events.conference.CONNECTION_ERROR,",e );
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
        room.current.on(window.JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
        room.current.on(window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, handleParticipantPropertyChange);
        room.current.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);

        room.current.on( window.JitsiMeetJS.errors.track.TRACK_IS_DISPOSED , (err)=>console.error(3130,err));
        room.current.on( window.JitsiMeetJS.errors.conference.RESERVATION_ERROR  , (err)=>console.error(3133,err));
        room.current.on( window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE   , (err)=>console.error(3132,err));
        room.current.on( window.JitsiMeetJS.errors.track.NOT_FOUND, (err)=>console.error(3134,err));
        room.current.on( window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND , (err)=>console.error(3135,err));
        
        
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
          let type=track.getType()
          let isMuted=track.isMuted()
          let id=track.getParticipantId()
          dispatch(trackMuteChange({id,type,isMuted}))
        });
         
        room.current.on(
          window.JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
          (updatedLocalStats) => {
            const newQuality = parseInt(updatedLocalStats.connectionQuality)
            if(newQuality < 30) {
              console.alert(1356, "localStats,", updatedLocalStats);
            } 

            setLocalConnectionQuality({jvbRTT: updatedLocalStats.jvbRTT, connectionQuality: newQuality})
          }
        );
        room.current.on(
          window.JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
          (id, newRemoteStat) => {
            const remotesID=[]
            for(let u of listRemoteUsersRef.current){
              remotesID.push(u.id)
            }
            dispatch(clearRemotesID(remotesID))
            const tmpRemoteStat = {...remoteStatsRef.current[id], ...newRemoteStat}
            dispatch(setRemoteStat({id, remoteStat: tmpRemoteStat}))
          }
        );

        window.logAllVideoTags = () => {
          for(let i=0; i< $("video").length; i++) {
            const v = $("video")[i]
            let found = false;
            if(v.id && v.id.endsWith("locationvideo")) {
              const participantID = v.id.replace("locationvideo", "");
              const stats = room.current.connectionQuality.getStats();
              let output = {height: "", fps: "", codec: ""}
              if(stats.codec) {
              const codecs = stats.codec[participantID]
                for(let i in codecs) {
                  output.codec += codecs[i].video ? "v:" + codecs[i].video : "";
                  output.codec += codecs[i].audio ? "a:" + codecs[i].audio : "";
                  output.codec += ","
                }
              }
              if(stats.framerate) {
                const framerates = stats.framerate[participantID]
                for(let i in framerates) {
                  output.fps += framerates[i] + ","
                }
              }
              if(stats.resolution) {
                const resolutions = stats.resolution[participantID]
                for(let i in resolutions) {
                  output.height += resolutions[i]?.height + ","
                }
              }
              output.codec = output.codec.replace(/,$|^,/ig, '')
              output.height = output.height.replace(/,$/, '')
              output.fps = output.fps.replace(/,$/, '')
              found = output.fps || output.height || output.codec
              if(found) {
                console.log(v, Number(output.fps), Number(output.height), output.codec)
              }
            }  
            if (!found && v.srcObject) {
              const settings = v.srcObject.getTracks()[0].getSettings()
              console.log(v, settings.frameRate.toFixed(0), settings.height)
            }
          }
        }

        room.current.on(window.JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (sender, data) => {
          const senderID = sender.getId()
          if(callStateRef.current && senderID == locationIDRef.current && data.type == "inCallAnswer") {
            if(data.status === "notInCall") {
              console.error(1192, "location respond with notInCall", locationEngagedRef.current)
              toast.error(<>Call ended as <i>{locationEngagedRef.current}</i> disconnected.</>, {autoClose: false})
              currentCallEnd("LocationNotInCall")
            } else {
              lastLocationResToCallState.current = Date.now();
            }
          }
          if(data.type == "inCallQuestion") {
            if(callStateRef.current && senderID == locationIDRef.current) {
              room.current.sendEndpointMessage(senderID, {type: "inCallAnswer", status: "inCall"});
            } else {
              room.current.sendEndpointMessage(senderID, {type: "inCallAnswer", status: "notInCall"});
            }
          }
        })
        localTracks.map((localTrack) => {
            room.current.addTrack(localTrack);
        });
        room.current.setDisplayName(myUsername.current);
        room.current.join();
        // jitsiMeetId will be empty of not jaas.
        AdminService.updateSelfPartId(room.current.myUserId(), localStorage.getItem("jitsiMeetId")).then((res) => {
          const data = res.data;
          if(data.code !== 0) {
            console.error("6752: ", data)
            toast.error("Error code 6752. Please contact customer support.", 10000);
          }
        }).catch((err) => {
          console.error("6753: ", err)
          toast.error("Error code 6753. Please contact customer support.", 10000)
        })
    }

    function reload(){
      console.alert(1395, "Reload called")
        unload().then(() => {
          setTimeout(()=>{
            if(window.electron) {
              window.electron.reload();
            } else {
              window.location.replaceWithoutWarn("#/")
            }
          }, Constant.TEN_SEC_INTERVAL)
        })
    }

    const onUserJoined = (id, user) => {
      console.alert(1396, "onUserJoined, id:", id, "user:", user.getDisplayName())
        let isFind = false;
        listRemoteUsersRef.current.forEach((cell, index) => {
            if (cell.id === id) {
                cell.user = user;
                listRemoteUsersRef.current[index] = cell;
                isFind = true;
            }
        });
        if (isFind) {
            return;
        }
        let new_user = { id: id, user: user };
        listRemoteUsersRef.current.push(new_user);
    }

    const onConnectionFailed = (error) => {
      // toast.error("Failed to connect to Jitsi. Retrying in " + Constant.TEN_SEC_INTERVAL/1000 + " seconds.", 9000);
      console.alert(1397, "onConnectionFailed", error)
      setTimeout(function () {
        //enter code here, it will run after wake up
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replaceWithoutWarn("#/")
        }
      }, Constant.TEN_SEC_INTERVAL);
    }

    async function unload(sendUserLeave) {
      console.alert(1398, "unload, sendUserLeave:", sendUserLeave)
      isLogout.current = true;

      try {
        if (localVideoTrack !== null) {
          localVideoTrack.detach($(`#mainRVideo`)[0]);
        }

        if (localAudioTrack !== null) {
          localAudioTrack.detach($(`#mainRAudio`)[0]);
        }

        for (let i = 0; i < localTracks.length; i++) {
          localTracks[i].dispose();
        }
      } catch (error) {
        console.error(1483, error)
      }
      if (room.current !== null) {
        try {
          room.current.removeEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
          room.current.removeEventListener(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
          await room.current.leave();
          room.current = null;
        } catch (error) {
          console.error(1484,error)
        }
      }

      if (connection.current !== null) {
        try {
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
          await connection.current.disconnect();
          connection.current = null;
        } catch (error) {
          console.error(1485,error)
        }
      }

      if(sendUserLeave) {
        await userLeaved(myUsername.current);
      }
    }

    const onLocalTracks = (tracks) => {
      tracks.forEach((localTrack, index) => {
          // console.alert(1399, "localTrack ", localTrack.getType())
          // localTrack.addEventListener(
          //     window.JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
          //     audioLevel => console.info(`Audio Level local: ${audioLevel}`));
          // localTrack.addEventListener(
          //     window.JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
          //     track => {
          //       console.info(`Local track: ${track.getType()} - ${track.isMuted()}`);
          //     }
          // );
          // localTrack.addEventListener(
          //     window.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
          //     () => {
          //       console.warn('Local track stopped');
          //         // unload();/**? disable for the feature to switch camera and mic */
          //     });
          // localTrack.addEventListener(window.JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
          //   deviceId =>
          //     console.info(`track audio output device was changed to ${deviceId}`));
          if (localTrack.getType() === Str.STR_VIDEO) {
              localStorage.setItem(Storages.LOCAL_CAMERA_ID, localTrack.deviceId);
              localTrack.attach($(`#mainRVideo`)[0]);
              localTrack.attach($("#localVideo")[0]);
              localVideoTrackRef.current=localTrack
              setLocalVideoTrack(localTrack);
              const isNowBlur = localStorage.getItem("blurMyBackground");
              (async () => {
                try{
                  if(isNowBlur) {
                    if(!virtualBackgroundEffect) {
                      virtualBackgroundEffect = await createVirtualBackgroundEffect()
                    }
                    localTrack.setEffect(virtualBackgroundEffect)
                  }
                } catch (err) {
                  console.error(err)
                  toast.error("An error occurred and couldn't change blur background")
                }
              })();
              if (!camera) {
                localTrack.mute().catch((err)=>{
                  console.error(1876,err)
                });
                // show video in setting modal
                var deviceId=localStorage.getItem(Storages.LOCAL_CAMERA_ID)
                if(!deviceId) return
                navigator.mediaDevices.getUserMedia({
                    audio:false,
                    video:{deviceId: { exact: deviceId }
                }}).then((steam)=>{
                    var localVideoEl=document.getElementById('localVideo')
                    if(localVideoEl) localVideoEl.srcObject=steam
                }).catch((err)=>console.error(6456,err));
              }
          } else if (localTrack.getType() === Str.STR_AUDIO) {
              localStorage.setItem(Storages.LOCAL_MIC_ID, localTrack.deviceId);
              localTrack.attach($(`#mainRAudio`)[0]);
              localAudioTrackRef.current=localTrack
              setLocalAudioTrack(localTrack);
              if (!mic) {
                  localTrack.mute().catch((err)=>{
                    console.error(1886,err)
                  });
              }
          }

          loadTrack(localTrack) 
        });
    }

    function loadTrack(localTrack) {
      try{
        if (joinState || isJoined) {
          let tempTrack = null;
          room.current && room.current.getLocalTracks().forEach((track) => {
              if (track.getType() === localTrack.getType()) {
                  tempTrack = track;
              }
          });
          if (tempTrack) {
              room.current.replaceTrack(tempTrack, localTrack);
          }
          else {
              room.current.addTrack(localTrack).catch((err)=>console.error(14225,err));
          }
          localTracks.push(localTrack);
        } else {
          setTimeout(() => loadTrack(localTrack), 1000)
        }              
      } catch (err) {
        console.error(1455, err)
        toast.error("Error while loading media tracks. Please reload", {autoClose: 5000});
      }
    }

    function getTrackFromUserName(username) {
        let ret = null;
        if (username === myUsername.current) {
            localTracks.forEach((localTrack) => {
                if (localTrack.getType() === Str.STR_VIDEO) {
                    ret = localTrack;
                }
            });
        }
        else {
          listRemoteUsersRef.current.forEach((cell) => {
                if (cell.user.getDisplayName() === username) {
                    let tracks = cell.user._tracks;
                    tracks.forEach((track) => {
                        if (track.type === Str.STR_VIDEO) {
                            ret = track;
                        }
                    })
                }
            });
        }

        return ret;
    }

    const onTrackAdded = (track) => {
      if (track.isLocal()) {// regist video particant id on db
          if (track.getType() === Str.STR_VIDEO) {
              let localData = { username: myUsername.current, videotrack: track };
              setLocalUserData(localData);
          }
          return;
      }
      else {
            // if remote track is muted. reception show it.
            let type=track.getType()
            let isMuted=track.isMuted()
            let id=track.getParticipantId()
            dispatch(trackMuteChange({id,type,isMuted}))
      }

      //   isSignalAccept.current = true;//sign to start remote track
      //Is it remote camera?
      let data = {};
      let username = "";
      const participant = track?.getParticipantId();
      const type = track.getType();
      let user_val = { 
          id: participant, 
          user: null, 
          videotrack: null, 
          audiotrack: null, 
          othertrack: null, 
          calltag: null, 
          calltagColor: null, 
          status: Constant.JOIN, 
          locationname: null, 
          username: null,
          desktopTrack: null,
          holdTimer: null,
          currentLangCode: "",
          inCall:false,
          callBy:null
      };
      listRemoteUsersRef.current.forEach((cell) => {
          if (cell.id === participant) {
              user_val.user = cell.user;
              username = cell.user.getDisplayName();
              return;
          }
      });
      // Sometimes some tracks have an endpoint that is not in our listRemoteUsers array. We just ignore them
      if(!user_val.user) return

      console.alert(1400, "onTrackAdded after isLocal, id", participant, "type", type, "username", username)

      if (!username || username === "") {
          return;
      }
      //get type from username
      data['username'] = username
      AdminService.getInfoFromUsername(data).then(response => {
        if (response.data.code !== 200) {
          console.error(1481, response.data)
          toast.error(response.data.message);
          return;
        }

        let result = response.data.data.data;
        if (result.type === Constant.RECEPTION) {
          let thisReception = listRemoteUsersRef.current.find(item => item.id == participant)
          if(!thisReception) {
            return;
          }
          if(!thisReception.tracks) {
            thisReception.tracks = []
          }
          thisReception.tracks = thisReception.tracks.filter(item => item.type != track.type) // remove the same type tracks
          thisReception.tracks.push(track) 
          return;
        }
        let fround = false;
        user_val.calltag = result.calltag;
        user_val.locationname = result.locationname;
        user_val.username = result.username;
        user_val.currentLangCode = result.currentLang || result.defaultLang;
        user_val.inCall = result.status > Constant.INCOMING
        if(result.status > Constant.INCOMING) user_val.callBy=result.otherpartid
        
        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.id === participant) {
              if (type === Str.STR_VIDEO) {
                if(remoteUser.videotrack) {
                    const locationVideoEl = $(`#${remoteUser.videotrack.getParticipantId() + 'location' + remoteUser.videotrack.getType()}`)[0]
                    if(locationVideoEl) {
                      remoteUser.videotrack.detach(locationVideoEl);
                      if(track) track.attach(locationVideoEl);
                    }
                  }

                  if(result.username === locationEngagedRef.current) {
                      if(remoteUser.videotrack) {
                          remoteUser.videotrack.detach($(`#locationVideo`)[0]);
                      }
                      track.attach($(`#locationVideo`)[0]);
                      setLocationVideoTrack(track);
                  }
                  remoteUser.videotrack = track;
                  remoteUser.othertrack = getTrackFromUserName(result.otherpartid);
                  remoteUser.status = result.status;
                  listData[index] = remoteUser;
              } else if (type === Str.STR_AUDIO) {
                  remoteUser.audiotrack = track;
                  listData[index] = remoteUser;

                  if (result.otherpartid === myUsername.current) {
                      track.attach($(`#locationAudio`)[0]);
                      setLocationAudioTrack(track);
                  }
              }
              fround = true;
          }
        });

        if (fround === true) {
          listLocationData = [...listData];
          setLocationData(listData);
          return;
        }

        if (type === Str.STR_VIDEO) {
          user_val.videotrack = track;
          user_val.othertrack = getTrackFromUserName(result.otherpartid);
          user_val.status = result.status;
        } else if (type === Str.STR_AUDIO) {
          user_val.audiotrack = track;
          if (result.otherpartid === myUsername.current) {
              track.attach($(`#locationAudio`)[0]);
              setLocationAudioTrack(track);  
          }
        }

        /**remove old location user video feed on sleeping */
        let removeIndex = -1;
        for (let i = 0; i < listData.length; i++) {
          if (listData[i].user.getDisplayName() === username && listData[i].id !== participant) {
            removeIndex = i;
            break;
          }
        }

        if (removeIndex !== -1) {
          listData.splice(removeIndex, 1);
        }
        listData.push(user_val);
        listLocationData = [...listData];
        setLocationData(listData);
      }, error => {
        console.error(1480, error)
        toast.error(Str.STR_SERVER_ACCESS_ERROR);
        return;
      });
    }

    const onRemoveTrack = (track) => {
      const participant = track?.getParticipantId();
      if(participant && listLocationData && listLocationData[0]) {
        const type = track.getType();
        console.alert(1401, "onRemoveTrack, id", participant, "type", type)
        listLocationData.forEach((user, index) => {
          if (user.id === participant) {
            if (type === "video") {
              track.detach($(`#${participant}locationvideo`)[0]);
            }
            else if (type === "audio") {
              track.detach($(`#${participant}locationaudio`)[0]);
            }
          }
        });
      } 
    }

    async function onConferenceJoined() {
        if (isLogout.current === true || window.location.hash === "#/login") {
            await unload(true)
            return
        }
        isJoined = true;
        setJoinState(true)
        updateUserStatus(Constant.JOIN);
        setReceiverCons()
        if(getStatsInterval) clearInterval(getStatsInterval);
        getStatsInterval = setInterval(() => {
          if(!room.current || !remoteStatsRef.current) return;

          const stats = room.current.connectionQuality.getStats();
          let tmpRemoteStats = {}
          for(let p in remoteStatsRef.current) {
            let height = "", fps = "", codec = "";
            const resolutions = stats.resolution[p]
            for (let i in resolutions) {
              height += resolutions[i]?.height + ","
            }
            const framerates = stats.framerate[p]
            for (let i in framerates) {
              fps += framerates[i] + ","
            }
            const codecs = stats.codec[p]
            for (let i in codecs) {
              codec += codecs[i].video ? "v:" + codecs[i].video : "";
              codec += codecs[i].audio ? "a:" + codecs[i].audio : "";
              codec += ","
            }
            codec = codec.replace(/,$|^,/ig, '') 
            height = height.replace(/,$/, '')
            fps = fps.replace(/,$/, '')
            tmpRemoteStats[p] = {...remoteStatsRef.current[p], codec, height, fps}
          }
          dispatch(setAllRemoteStats(tmpRemoteStats))
        }, 3000);
    }

    async function updateUserStatus(status, otherpartid) {
      try{
        const updateRes = await AdminService.updateReceptionStatus({newStatus: status, newOtherpartid: otherpartid});
        if(updateRes?.data?.code !== 0) {
          console.error(1479, updateRes.data)
          toast.error(updateRes.data.msg);
        } else {
          setUserStatus(status);
        }
      } catch (err) {
        console.error(1479, err)
        toast.error(err.message);
      }
    }

    const onUserLeft = (id, user) => {
      console.alert(1405, "onUserLeft, id:", id, "user:", user.getDisplayName(), "locationEngagedRef", locationEngagedRef.current)
        if (user.getDisplayName() == locationEngagedRef.current) {
            setLocationEngaged(null)
            setCallLoading(false)
            clearTimeout(callLoadingTimeoutRef.current)
            callLoadingTimeoutRef.current=null
            clearInterval(updtLocActvIntv)
        }
        let listData;
        //locations list
        listData = [...listLocationData];
        listData.forEach((cell, index) => {
            if (cell.id === id) {
                if (locationNum.current > -1) {
                    //remove current call track
                    let locationName = listData[locationNum.current].user.getDisplayName();
                    if (locationName === user.getDisplayName()) {
                        currentCallEnd('Failed');
                    }
                }
                if (index < locationNum.current) {
                    locationNum.current = locationNum.current - 1;
                }

                listLocationData.splice(index, 1);
                return;
            }
        });
        setLocationData(listLocationData);

        let remove = -1;
        listRemoteUsersRef.current.forEach((cell, index) => {
            if (cell.id === id) {
                remove = index;
            }
        });

        if (remove !== -1) {
          listRemoteUsersRef.current.splice(remove, 1);
        }

        if (isLogout.current === false) {
            userLeaved(user.getDisplayName());
        }
    }

    function onConferenceLeft() {
      if (isLogout.current === false) {
        console.alert(7781, "onConferenceLeft")
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replaceWithoutWarn("#/")
        }
      }
    }
		
    const handleParticipantPropertyChange = (participant, propertyName, oldValue, newValue) => {
      // console.alert(
      //   1408,
      //   "handleParticipantPropertyChange",
      //   participant.getId(),
      //   participant.getDisplayName(),
      //   propertyName,
      //   oldValue,
      //   newValue
      // );
        if (/*isSignalAccept.current === false || */newValue === Constant.NONE_VALUE) {
            return;
        }

        /*update call tag that come from location.*/
        if (propertyName === Str.STR_CALL_TYPE) {
            let participantId = participant.getId();
            let listData = [...listLocationData];
            listData.forEach((remoteUser, index) => {
                if (remoteUser.id === participantId) {
                    remoteUser.calltag = newValue;
                    return;
                }
            });
            listLocationData = [...listData];
            setLocationData(listData);
            return;
        }
        /*update call tag color that come from location.*/
          if (propertyName === Str.STR_CALL_TYPE_COLOR) {
            if(!newValue) return
            let participantId = participant.getId();
            let listData = [...listLocationData];
            listData.forEach((remoteUser, index) => {
                if (remoteUser.id === participantId) {
                    remoteUser.calltagColor = newValue;
                    return;
                }
            });

            listLocationData = [...listData];
            setLocationData(listData);
            return;
        }
        /*handle call from receptions.*/
        let receptionName = participant.getDisplayName();

        let locationName = propertyName;
        switch (newValue) {
            case Constant.CALL_END_VALUE:
                callEnd(receptionName, locationName);
                break;
            case Constant.CALL_INCOMING_VALUE:
                callIncoming(receptionName, locationName);
                break;
            case Constant.CALL_ACCEPT_VALUE:
                callJoined(receptionName, locationName);
                break;
            // case Constant.CALL_PAUSE_VALUE:
            //     callHoldOn(receptionName, locationName);
            //     break;
            // case Constant.CALL_PLAY_VALUE:
            //     callHoldOff(receptionName, locationName);
            //     break;
            default:
                break;
        }
    }

    function callEnd(receptionName, locationName) {
      console.alert(1410, "callEnd", receptionName, locationName)

        if (locationNum.current > -1) {
            if (listLocationData[locationNum.current].user.getDisplayName() === locationName) {
                currentCallEnd();
            }
        }
        var locUsername;
        let listData = [...listLocationData];
        var locUsername;
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                locUsername=remoteUser.username
                remoteUser.status = Constant.JOIN;
                remoteUser.othertrack = null;
                remoteUser.inCall=false
                remoteUser.callBy=null
                remoteUser.calltag=null
                remoteUser.calltagColor=null
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);

        if(monitorCallStatusRef.current && monitorCallLocationRef.current.username==locUsername) {
          stopMonitorCall();
          toast.info("The call has ended.")
        }

        if(waitingForHangupLocationUsername.current === locationName) {
          waitedLocationHangedup.current = true;
        }
    }

    function callIncoming(receptionName, locationName) {
      console.alert(1412, "callIncoming", receptionName, locationName)

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.INCOMING;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
    }

    function callJoined(receptionName, locationName) { 
        console.alert(1415, "callJoined", receptionName, locationName)

        if (locationNum.current > -1) {
            if (listLocationData[locationNum.current].user.getDisplayName() === locationName) {
              if(receptionName != myUsername.current){
                  handleCancelEngagedLocation(locationNum.current) 
              }
              setLocationName(Str.STR_EMPUTY);
              setLocationID(null)
              setPlayState(false);
              locationNum.current = -1;

              $(`#locationVideo`)[0].srcObject = null;
              setGlowState(false);
              if (locationVideoTrack !== null) {
                locationVideoTrack.detach($(`#locationVideo`)[0]);
                setLocationVideoTrack(null);
              }

              if (locationAudioTrack !== null) {
                  locationAudioTrack.detach($(`#locationAudio`)[0]);
                  setLocationAudioTrack(null);
              }
            }
        }

        let listData = [...listLocationData];
        let rec=listReceptionDataRef.current.find((r)=>r.username===receptionName)
        let displayName=rec?.displayName || receptionName
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.othertrack = getTrackFromUserName(receptionName);
                remoteUser.inCall=true;
                remoteUser.callBy=displayName
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
    }

    function callHoldOn(locationName) {
        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.HOLD;
                remoteUser.holdTimer = 1;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
        if(monitorCallLocationRef.current && monitorCallLocationRef.current.username== locationName){
          setMonitorCallHold(true)
        }
    }

    function callHoldOff(locationName) {
        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.CALL;
                delete remoteUser.holdTimer;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
        if(monitorCallLocationRef.current && monitorCallLocationRef.current.username== locationName){
          setMonitorCallHold(false)
        }
    }

    const handleLocalCamera = (event) => {
        event.stopPropagation()
        if (localVideoTrack === null) {
          toast.error("No camera found. Please reload.")
          return;
        }

        setCamera(localVideoTrack.isMuted());
        if (localVideoTrack.isMuted()) {
            localVideoTrack.unmute().catch((err)=>{
              console.error(1891,err)
            });
        } else {
            localVideoTrack.mute().catch((err)=>{
              console.error(1892,err)
            });
            clearTimeout(timeOutForCamera)
        }
        if(callState ===false){
          var timeout=setTimeout(() => {
              setCallStateAndLocalVideo(false)
          }, 5000)
          setTimeOutForCamera(timeout)
      }
    }
    
    useEffect(()=>{
        if(callState){
            clearTimeout(timeOutForCamera)
        }
    },[callState])

    useEffect(() => {
        if (!joinState || room.current === null) {
            return;
        }

        let lastCameraId=localStorage.getItem(Storages.LOCAL_CAMERA_ID)
        cameras.forEach(async (cell) => {
            if (cell.selected === true && (!lastCameraId || lastCameraId != cell.deviceId)) {
              if (localVideoTrack) {
                  // await localVideoTrack.dispose();
              }
              window.JitsiMeetJS.createLocalTracks({
                  devices: [Str.STR_VIDEO],
                  cameraDeviceId: cell.deviceId
              })
              .then(onLocalTracks)
              .catch(error => {
                handleErrorTrack(error, 1477, 'camera')
                if(cell.deviceId != lastCameraId){
                  let cmr=cameras.find((c)=>c.deviceId==lastCameraId)
                  if(cmr){
                    dispatch(selectCamera({deviceId:cmr.deviceId}))
                  }
                }
              });
          }
        })
    }, [cameras])

    const handleLocalMic = (event) => {
        event.stopPropagation()
        if (!localAudioTrack) {
          toast.error("No microphone found. Please reload.")
          return;
        }

        setMic(localAudioTrack.isMuted());
        if (localAudioTrack.isMuted()) {
            localAudioTrack.unmute().catch((err)=>{
              console.error(1889,err)
            });
        } else {
            localAudioTrack.mute().catch((err)=>{
              console.error(1890,err)
            });
        }
    }

    useEffect(() => {
        if (!joinState || room.current === null) {
            return;
        }

        let lastMicId=localStorage.getItem(Storages.LOCAL_MIC_ID)
        mics.forEach((cell) => {
            if (cell.selected === true && (!lastMicId || lastMicId != cell.deviceId)) {
                if (localAudioTrack) {
                    // await localAudioTrack.dispose();
                }

                window.JitsiMeetJS.createLocalTracks({
                    devices: [Str.STR_AUDIO],
                    micDeviceId: cell.deviceId
                })
                .then(onLocalTracks)
                .catch(error => {
                  handleErrorTrack(error, 1475, 'microphone')
                  if(cell.deviceId !=lastMicId){
                    dispatch(selectMic({deviceId:lastMicId}))
                  }
                });
            }
        })
    }, [mics])

    function currentCallEnd(status) {
        var oldCallState=callStateRef.current
        if (isLogout.current === false) {
          updateUserStatus(Constant.JOIN);
        }

        clearInterval(checkCallStateInterval)

        if (locationNum.current > -1 && listLocationData.length > locationNum.current) {
            let listData = [...listLocationData];
            listData[locationNum.current].othertrack = null;
            listData[locationNum.current].status = Constant.JOIN;
            listLocationData = [...listData];
            setLocationData(listData);
            try {
              listData[locationNum.current].videotrack.detach($(`#locationVideo`)[0]);
              if (listData[locationNum.current].audiotrack != null) {
                  listData[locationNum.current].audiotrack.detach($(`#locationAudio`)[0]);
              }
            } 
            catch (error) {
              console.error(1993,error)
            }
            setGlowState(false);
        }
        setLocationName(Str.STR_EMPUTY);
        setLocationID(null)
        setCallStateAndLocalVideo(false);
        setPlayState(false);
        volumeWarningShowed.current = false;
        handleCancelEngagedLocation(locationNum.current)
        locationNum.current = -1;
        try {
          if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
            setLocationVideoTrack(null);
            setGlowState(false);
          }

          if (locationAudioTrack !== null) {
              locationAudioTrack.detach($(`#locationAudio`)[0]);
              setLocationAudioTrack(null);
          }
  
        } catch (error) {
          console.error(1994,error)
        }
        /**update call status unless current user logout */
        if (oldCallState) {        
            let data = {};
            data['username'] = myUsername.current;
            data['type'] = Constant.RECEPTION;
            data['status']=status
            if(locationName){
              data['token']=callToken.current[locationName && locationName.toLowerCase() ] || null
            }
            
            AdminService.currentCallEnd(data)
                .then(
                    response => {
                        if (response.data.code !== 200) {
                          console.error(1473, response.data.msg)
                          toast.error(response.data.msg);
                        }
                        let obj=callToken.current
                        obj[locationName && locationName.toLowerCase()]=null
                        callToken.current=obj
                    },
                    error => {
                      console.error(1472, error)
                      toast.error(Str.STR_SERVER_ACCESS_ERROR);
                    }
                );
        }

        localStorage.removeItem("reload_lock")
    }

    const handleLocationCall =async () => {
      try {  
        if (locationNum.current < 0) {
            return;
        }
        let listData = [...locationData];
        let locationName = listData[locationNum.current].user.getDisplayName();
        let properties=room.current.getParticipantById(listData[locationNum.current].id)._properties
        var token=properties[constant.CALL_INCOMING_TOKEN]
        if (locationName === null || locationName === "") {
            return;
        }
        
        if (callState === false) {
            setCallLoading(true)
            localAudioTrackRef.current && localAudioTrackRef.current.unmute()
            var callLoadingTimeout= setTimeout(async() => {
              var callAddResult = await AdminService.locationCallAdd({locationName, token})
              if(callAddResult.data?.code == 1) {
                toast.warn("The location is being called by another reception.")
              } else if (callAddResult.data?.code != 0) {
                toast.error(callAddResult.data.msg, {autoClose: 10 * 1000})
              }
              if(callAddResult.data?.code != 0) {
                setCallLoading(false)
                if(callLoadingTimeoutRef.current) {
                  clearTimeout(callLoadingTimeoutRef.current)
                  callLoadingTimeoutRef.current=null
                }
                return handleCancelEngagedLocation(locationNum.current) 
              }
              let obj= callToken.current
              obj[locationName && locationName.toLowerCase()]=callAddResult.data.token
              callToken.current= obj

              localStorage.setItem("reload_lock", 1)
              room.current && room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
              room.current && room.current.setLocalParticipantProperty(locationName, Constant.CALL_ACCEPT_VALUE);

              setCallStateAndLocalVideo(true);
              setPlayState(true);
              if (locationData[locationNum.current] && locationData[locationNum.current]?.audiotrack) {
                  locationData[locationNum.current].audiotrack.attach($(`#locationAudio`)[0]);
                  setLocationAudioTrack(locationData[locationNum.current].audiotrack)
              }
              //set other small video
              if(listData[locationNum.current]){
                listData[locationNum.current].othertrack = localVideoTrack;
                listData[locationNum.current].status = Constant.CALL;
              }
              listLocationData = [...listData];
              setLocationData(listData);
              updateUserStatus(Constant.CALL, locationName);
              setCallLoading(false)
              //avoid start call after location left room.
              if(!callLoadingTimeoutRef.current){ 
                return currentCallEnd() // check callEnd & handleCancelEngagedLocation function 
              }
              callLoadingTimeoutRef.current=null

              let callAddResultUser = callAddResult.data.data?.user
              if(callAddResultUser) {
                let tmpAllLocations = [...allLocationsRef.current];
                let locationFound = tmpAllLocations.find(item => item.userID == callAddResultUser.id)
                if(locationFound && locationFound.cardDetectionEnabled != callAddResultUser.cardDetectionEnabled) {
                  locationFound.cardDetectionEnabled = callAddResultUser.cardDetectionEnabled
                  _setAllLocations(tmpAllLocations)
                }
              }

              startCheckCallStateInterval()
            }, 2000);
            callLoadingTimeoutRef.current=callLoadingTimeout
            // send to location for disable button disconnected.
            await AdminService.sendMessage({to: locationName, event: 'disable_call_end'})
        } else {
            room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(locationName, Constant.CALL_END_VALUE);
            currentCallEnd();
        }
      } catch (error) {
          console.error(2021,error)
          setCallLoading(false)
      }
    }

    const handleLocationPlay = async () => {
        if (callState === false || locationNum.current < 0) {
          return;
        }

      let listData = [...locationData];
      let locationName = listData[locationNum.current].user.getDisplayName();
      if(!locationName) {
        console.error(1275, "No location name on handleLocationPlay", locationName)
        toast.error("No location found with this name.", {autoClose: 5 * 1000})
        return;
      }

      const token = callToken.current[locationName.toLowerCase()];

      if (listData[locationNum.current].status === Constant.CALL) {
          // listData[locationNum.current].status = Constant.HOLD;
          // listData[locationNum.current].holdTimer = 1;
          // room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
          // room.current.setLocalParticipantProperty(locationName, Constant.CALL_PAUSE_VALUE);
          const updateRes = await AdminService.updateCallHoldStatus({hold: true, locationName, callToken: token });
          if(updateRes.data.code !== 0) {
            console.error(1271, "Error while putting the call on hold", updateRes.data)
            toast.error("Error while putting call on hold. Error code: " + updateRes.data.code, {autoClose: 5 * 1000})
            return;
          }
            
          setPlayState(false);
          if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
            setLocationAudioTrack(null);
          }

      } else if (listData[locationNum.current].status === Constant.HOLD) {
          // listData[locationNum.current].status = Constant.CALL;
          const updateRes = await AdminService.updateCallHoldStatus({hold: false, locationName, callToken: token });
          if(updateRes.data.code !== 0) {
            console.error(1272, "Error while resuming the call.", updateRes.data)
            toast.error("Error while resuming the call. Error code: " + updateRes.data.code, {autoClose: 5 * 1000})
            return;
          } 

          // room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
          // room.current.setLocalParticipantProperty(locationName, Constant.CALL_PLAY_VALUE);
          setPlayState(true);

          if (locationData[locationNum.current].audiotrack !== null) {
              locationData[locationNum.current].audiotrack.attach($(`#locationAudio`)[0]);
              setLocationAudioTrack(locationData[locationNum.current].audiotrack);
          }
      }
    }

    const handleClickLocationItem = async (index) => {
      try{
        if ((callState && playState) || callLoading) {
            return;
        }
        const username = locationData[index]?.user.getDisplayName()
        if (username === locationEngaged) return handleCancelEngagedLocation(index)

        closeAllModals();

        console.alert(1427, "handleClickLocationItem, username:", username)

        if (locationData[index] && locationData[index].status > Constant.INCOMING) {
            setCallStateAndLocalVideo(true);
            if (locationData[index].status === Constant.HOLD) {
                setPlayState(false);
            }
            else {
                setPlayState(true);
            }
        }
        else {
            setCallStateAndLocalVideo(false);
            setPlayState(false);
        }

        locationNum.current = index;
        if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
        }

        if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
        }
        let locName=locationData[index] && locationData[index].locationname
        setLocationName(locName);
        setLocationID(locationData[index] && locationData[index].id)
        if (locationData[index]?.videotrack !== null) {
            locationData[index].videotrack.attach($(`#locationVideo`)[0]);
            setLocationVideoTrack(locationData[index].videotrack);
            setGlowState(true);
        }

        if (locationData[index]?.audiotrack !== null) {
            setLocationAudioTrack(locationData[index].audiotrack);
        }
        setLocationEngaged(username)
        
        await AdminService.updateLastActivity(username)
        if (updtLocActvIntv) clearInterval(updtLocActvIntv)
        updtLocActvIntv = setInterval(async () => {
          try{
            await AdminService.updateLastActivity(username)
          } catch (err) {
            // no need to do anything
          }
        }, Constant.UPDATE_ACTIVITY_INTERVAL_DELAY)
      } catch (err) {
        console.error(1470, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const handleClickHoldLocation = async (username) => {
      try{
        if (callState === true && playState === true) {
          return;
        }

        const confirm = await window.confirmAsync.show(
          <h6 style={{fontSize: "1.35rem", marginBottom: "0px"}}>Confirm</h6>, 
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>
            Another reception is holding this call. Hang up that and continue here?
          </h6>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 },
            { value: 0, color: "default", text: "No", close: 1 }
          ]
        )
        if(confirm !== 1) {
          return;
        }

        setShowLoading(true)
        const location = locationData.find((l)=>l.username===username);
        if(!location) {
          toast.error("No location found for this call")
          return
        }
        // hangUpHoldCall will tell location to hangup
        const hangUpResult = await AdminService.hangUpHoldCall({locationUsername: username});
        if(hangUpResult.data?.code !== 0) {
          console.error(1562, hangUpResult.data)
          toast.error(hangUpResult.data.msg, {autoClose: 10000})
          setShowLoading(false)
          return
        }

        let checkAfter30SecondsTimeout;

        // After location hangs up, the callEnd method will be called. 
        // If callEnd is called and waitingForHangupLocationUsername, we will continue
        waitingForHangupLocationUsername.current = username;

        const waitForLocationToHangupInterval = setInterval(() => {
          if(waitedLocationHangedup.current) {
            waitingForHangupLocationUsername.current = "";
            waitedLocationHangedup.current = false;

            clearTimeout(checkAfter30SecondsTimeout)
            //MAAST_MAAL!
            setTimeout(async () => {
              // we should find index again as it may have changed during this time
              const indexAgain = locationData.findIndex((item) => item.user?.getDisplayName() === username)
              if(indexAgain === -1) {
                toast.error("Some error occurred and we couldn't find the location you were trying to reach. Please call them manually.", {autoClose: 10 * 1000})
                setShowLoading(false)
                return;
              }
              await handleClickLocationItem(indexAgain);
              setTimeout(async () => {
                await handleLocationCall()
                setShowLoading(false)
              }, 500);
            }, 500);
          }
        }, 500)

        // If for some reason, callEnd is not called, show error
        checkAfter30SecondsTimeout = setTimeout(() => {
          toast.error("Some error occurred and we couldn't find the location you were trying to reach. Please call them manually.", {autoClose: 10 * 1000})
          waitingForHangupLocationUsername.current = "";
          clearInterval(waitForLocationToHangupInterval)
          setShowLoading(false)
        }, 30 * 1000);

      } catch (err) {
        console.error(1567, err)
        toast.error(err.message, {autoClose: 10000})
        setShowLoading(false)
      }
    }

    const handleCancelEngagedLocation = async (index) => {
        // if (callState === true && playState === true) return; // comment out otherwise endCall wont cancel engage
        if(callLoading) return
        closeAllModals();
        if(monitorCallStatus) {
          stopMonitorCall();
          return;
        }


        const username =locationData[index] &&  locationData[index].user.getDisplayName()
        console.alert(1428, "handleCancelEngagedLocation, username:", username)

        if (locationEngaged != username) return
        if (updtLocActvIntv) clearInterval(updtLocActvIntv)
        setPlayState(false);
        setCallStateAndLocalVideo(false);
        setGlowState(false)
        if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
            setLocationVideoTrack(null);
        }

        if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
            setLocationAudioTrack(null);
        }
        setLocationEngaged(null)
        setLocationName(null)
        setLocationID(null)
        setLocationConnectionIssue(false)
    }
    const handleItemSleep = async (index) => {
      try{
        await AdminService.updateLastActivity(allLocations[index].username)
        await AdminService.sendMessage({to: allLocations[index].username,event: 'unSleep', msg:'wake up'})
      } catch (err) {
        console.error(1469, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const handleItemCall = () => {
        setTimeout(function () {
            handleLocationCall()
        }, 1000);
    }

    async function userLeaved(username) {
        try {
          let data = {username};
          await AdminService.userLeave(data);
        } catch (error) {
          console.error(1468, error)
        }
    }

    /**
    * Function that handle user status when user status is changed.
    */
    const changeUserStatus = (newStatus) => {
      updateUserStatus(newStatus);
    }

    /*Sort reception list by call status*/
    function sortByUserStatus() {
        let list = [...listReceptionDataRef.current];

        for (let i = 0; i < list.length - 1; i++) {
            for (let j = i + 1; j < list.length; j++) {
                if (list[i].status < list[j].status) {
                    let item1 = { ...list[i] };
                    let item2 = { ...list[j] };
                    list[i] = item2;
                    list[j] = item1;
                }
                else if (list[i].status === list[j].status && list[i].username.localeCompare(list[j].username) === 1) {
                    let item1 = { ...list[i] };
                    let item2 = { ...list[j] };
                    list[i] = item2;
                    list[j] = item1;
                }
            }
        }

        listReceptionDataRef.current = [...list];
        setReceptionData(list);

    }

    function setCallStateAndLocalVideo(flag) {
      console.alert(1432, "setCallStateAndLocalVideo, flag:", flag)
      setCallState(flag);
      callStateRef.current=flag
      var localVideo=localVideoTrackRef.current
      var localAudio=localAudioTrackRef.current
        if (localVideo !== null) {
            if (flag) {
              localVideo.unmute().catch((err)=>{
                console.error(1891,err)
              });
            }
            else {
              localVideo.mute().catch((err)=>{
                console.error(1892,err)
              });
            }
            setCamera(flag);
        }
        else {
            /**if location call is ended itself */
            localTracks.forEach((cell) => {
                if (cell.getType() === Str.STR_VIDEO) {
                    if (flag === true) {
                        cell.unmute().catch((err)=>{
                          console.error(1893,err)
                        });
                    }
                    else {
                        cell.mute().catch((err)=>{
                          console.error(1894,err)
                        });
                    }
                    setCamera(flag);
                    return;
                }
            });
        }
        if (localAudio !== null) {
            if (flag) {
              localAudio.unmute().catch((err)=>{
                console.error(1895,err)
              });
            }
            else {
              localAudio.mute().catch((err)=>{
                console.error(1898,err)
              });
            }
            setMic(flag);
        }
        else {
            /**if location call is ended itself */
            localTracks.forEach((cell) => {
                if (cell.getType() === Str.STR_AUDIO) {
                    if (flag === true) {
                        cell.unmute().catch((err)=>{
                          console.error(1896,err)
                        });
                    }
                    else {
                        cell.mute().catch((err)=>{
                          console.error(1897,err)
                        });
                    }
                    setMic(flag);
                    return;
                }
            });
        }
        setCallState(flag);
        callStateRef.current=flag
    }

    const handleCloseModalPayReq = () => {
        setPayReqModal(false)
    }
    const handleOpenModalPayReq = () => {
        setPayReqModal(true)
    }

    const setReceiverCons = () => {
      if(!room.current) return

      const stageTracks = [];
      if(locationVideoTrack) {
        stageTracks.push(locationVideoTrack)
      } else if (monitorCallStatus) {
        const monitorReceptionVideoTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_VIDEO)
        const monitorLocationVideoTrack = monitorCallLocationRef.current.videotrack;
        stageTracks.push(monitorReceptionVideoTrack, monitorLocationVideoTrack)
      }
      //{"constraints":{"4942f96f-v0":{"maxHeight":720},"ffbe59bb-v0":{"maxHeight":180}},"defaultConstraints":{"maxHeight":0},"lastN":-1,"onStageSources":["4942f96f-v0"],"selectedSources":[]}
      // setTimeout(() => {
        let conf = {
          defaultConstraints: { maxHeight: 180 },
          constraints: {},
          lastN: -1,
          // selectedSources: [],
          onStageSources: [], // in new jitsi version, we use sourceName instead of endpointID
        }
        // make all videos small
        for(let location of locationData) {
           // in new jitsi version, we use sourceName instead of endpointID
          if(location.videotrack && location.videotrack.getSourceName()) {
            conf.constraints[location.videotrack.getSourceName()] = { maxHeight: 180 };
          }
        }
        if(stageTracks[0]){
          let maxHeightVideo=Number(localStorage.getItem(Storages.MAX_HEIGHT_VIDEO) || 1080)
          const defaultLastN = rightSidebarRef.current ? -1 : stageTracks.length
          for(let track of stageTracks) {
            if(track && track.getSourceName()){
              // in new jitsi version, we use sourceName instead of endpointID
              console.alert(1377, `stageTrack ${track.getSourceName()}`)
              if(maxHeightVideo==0) conf.lastN=0  // Setting LastN to 0 means no participant video is received.
              else conf.lastN=defaultLastN
              conf.constraints[track.getSourceName()] = { maxHeight: maxHeightVideo }
              conf.onStageSources.push(track.getSourceName())
            }
          }
        }
        room.current.setReceiverConstraints(conf)
      // }, 500);
    }

    const showPrintFileModal = () => {
        setPrintFileModalShow(true)
    }
    const closePrintFileModal = () => {
      setPrintFileModalShow(false)
    }
    const handleRequestForScan=()=>{
        setDefaultBatch('')
        setOpenScanRequestModal(true)
    }
    const startCardDetection= () => {
      setOpenCardDetection(true)
    }
    const closeCardDetection = () => {
      setOpenCardDetection(false)
    }
    const handleCloseScanRequestModal=(click)=>{
      // stupid mui calls this method on backdrop click
      if(click) {
        if(defaultBatch && defaultBatch !='')  handleOpenGallery()
        setOpenScanRequestModal(false)
      }
    }
    const handleOpenGallery=()=>{
      localStorage.setItem("reload_lock", 1)
      setShowGalleryModal(true)
    }
    const handleCloseGallery=()=>{
      setShowGalleryModal(false)
      localStorage.removeItem("reload_lock")
    }
    const toggleShareScreen = async () => {
      try{
        console.alert(1440, "toggleShareScreen, locationEngaged:", locationEngagedRef.current)
        await AdminService.sendMessage({to: locationEngagedRef.current, event: 'toggle-share-screen'})
      } catch (err) {
        console.error(1464, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    
    const toggleBlur = async () => {
      try{
        if(localVideoTrack) {
          const isNowBlur = localStorage.getItem("blurMyBackground");
          if(isNowBlur) {
            localVideoTrack.setEffect(undefined)
            localStorage.removeItem("blurMyBackground")
          } else {
            if(!virtualBackgroundEffect) {
              virtualBackgroundEffect = await createVirtualBackgroundEffect()
            }
            localVideoTrack.setEffect(virtualBackgroundEffect)
            localStorage.setItem("blurMyBackground", 1)
          }
        }
      } catch (err) {
        console.error(err)
        toast.error("An error occurred and couldn't change blur background")
      }
    }
    
    const handleExitFullScreenEngaged=()=>{
      if(monitorCallStatus) {
        stopMonitorCall();
        return;
      }
      if(!locationData) return
      for (let index in locationData){
        if(locationData[index] && locationData[index].user && locationData[index].user.getDisplayName() === locationEngaged){
          handleCancelEngagedLocation(index)
          break;
        }
      }
    }

    const captureLocationCameraImage = async (username) => {
      try {
        if(!username) username = locationEngagedRef.current
        if(!username) {
          return toast.warning("Location not found.")
        }
        captureImageToastID.current = toast.info(<div>Capturing. please wait...</div>, {autoClose: false});
        await AdminService.sendMessage({
          to: username,
          event: "capture-camera-image"
        })
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const openIssueReporter = async () => {
      setIssueReporterOpen(true)
    }

    const closeIssueReporter = async () => {
      setIssueReporterOpen(false)
    }

    
    const handleDeviceManagement=(username)=>{
      if(!username) username = locationEngagedRef.current
      if(!username) {
        return toast.warning("Location not found.")
      }
      setLocationUsernameForDeviceManager(username)
      setOpenDeviceManagement(true)
    }
    const closeDeviceManagement=()=>{
      setOpenDeviceManagement(false)
    }

    const closeAllModals = () => {
      setOpenDeviceManagement(false);
      setOpenScanRequestModal(false);
      setPrintFileModalShow(false);
      setPayReqModal(false);
      setOpenCardDetection(false);
      setShowModalMBoard(false);
    }

    const handleErrorTrack=(err,code,device)=>{
      // show error related to track only once
      if(device == "camera") {
        if(cameraErrorShown.current) {
          return
        }
        cameraErrorShown.current = true
      }
      if(device == "microphone") {
        if(microphoneErrorShown.current) {
          return
        }
        microphoneErrorShown.current = true
      }

      console.error(code, device, err)
      toast.error(device + ": " + err.message, 5000);
    }

    const nonElectronUserLeave = async () => {
      try{
        setShowLoading(true)
        // const auth0Client = await createAuth0Client({
        //   domain: process.env.REACT_APP_AUTH0_DOMAIN,
        //   clientId: process.env.REACT_APP_AUTH0_CLIENTID,
        // });
        // if(await auth0Client.isAuthenticated()) {
        //   await auth0Client.logout({ logoutParams: { returnTo: window.location.origin }});
        // }
        var token = tokenService.get();
        if (token) {
          await AdminService.userLeave({
            username: myUsername.current,
          })
          // Commented as it is firing in the useEffect's return function anyway. 
          // Calling it here as well will sometimes result in "You have already left the conference" error
          // await unload(false)
        } else {
          // await unload(false)
        }
        disconnectSocket()
        tokenService.remove()
        window.location.href="#/"
      } catch (err) {
        setShowLoading(false)
        console.error(1494, err)
        window.confirmAsync.show(
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Error</h6>, 
          <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
            There was an error when trying to exit. Do you want to exit anyway?
          </span>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 },
            { value: 0, color: "default", text: "No", close: 1 }
          ]
        ).then((value) => {
          if(value === 1) {
            disconnectSocket()
            tokenService.remove()
            window.location.href="#/"
          }
        })
      }
    }
    const handleOpenMessagingBoard=(username)=>{
      if(!username) username = locationEngagedRef.current
      if(!username) {
        return toast.warning("Location not found.")
      }
      setLocationMessagingBoard(username)
      setShowModalMBoard(true)
    }

    const startMonitorCall = async (username) => {
      if(monitorCallStatus) {
        stopMonitorCall();
      }
    
      const location = locationData.find((l)=>l.username===username);
      if(!location) {
        toast.error("No location found for this call")
        return
      }
      monitorCallLocationRef.current = location

      if(!location.videotrack || !location.audiotrack) {
        toast.warn("No media track found for the location")
      }

      // Sometimes location.othertrack is null
      let reception;
      if(location.othertrack) {
        reception = listRemoteUsersRef.current.find(item => item.id == location.othertrack.ownerEndpointId && item.tracks)
      }
      // if not found, location.callBy is username, sometimes it is the same as displayname
      if(!reception && location.callBy) {
        // Sometimes two users with the same name are in listRemoteUsersRef but only one has tracks
        reception = listRemoteUsersRef.current.find(item => item.user?.getDisplayName() == location.callBy && item.tracks)
      }
      // if not found, find the reception in our own reception data, then find their tracks base on displayname
      if(!reception && listReceptionDataRef.current[0]) {
        let receptionWithoutTracks = listReceptionDataRef.current.find(item => item.otherpartid === location.locationname)
        if(receptionWithoutTracks) {
          reception = listRemoteUsersRef.current.find(item => item.user?.getDisplayName() == receptionWithoutTracks.displayName && item.tracks)
        }
      }

      if(!reception || !reception.user || !reception.tracks) {
        toast.error("No reception found for this call")
        return
      }
      monitorCallReceptionRef.current = reception

      let receptionVideoTrack = reception.tracks.find(item => item.type == Str.STR_VIDEO)
      let receptionAudioTrack = reception.tracks.find(item => item.type == Str.STR_AUDIO)

      if(!receptionVideoTrack || !receptionAudioTrack) {
        toast.warn("No media track found for the reception")
      }

      location.videotrack && location.videotrack.attach($("#monitorLocationVideo")[0])
      location.audiotrack && location.audiotrack.attach($("#monitorLocationAudio")[0])

      receptionVideoTrack && receptionVideoTrack.attach($("#monitorReceptionVideo")[0])
      receptionAudioTrack && receptionAudioTrack.attach($("#monitorReceptionAudio")[0])
      setMonitorCallStatus(true)
    } 

    const stopMonitorCall = () => {
      if(monitorCallLocationRef.current) {
        monitorCallLocationRef.current.videotrack && monitorCallLocationRef.current.videotrack.detach($("#monitorLocationVideo")[0])
        monitorCallLocationRef.current.audiotrack && monitorCallLocationRef.current.audiotrack.detach($("#monitorLocationAudio")[0])
        monitorCallLocationRef.current = null
      }
      if(monitorCallReceptionRef.current && monitorCallReceptionRef.current.tracks) {
        let receptionVideoTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_VIDEO)
        let receptionAudioTrack = monitorCallReceptionRef.current.tracks.find(item => item.type == Str.STR_AUDIO)
        receptionVideoTrack && receptionVideoTrack.detach($("#monitorReceptionVideo")[0])
        receptionAudioTrack && receptionAudioTrack.detach($("#monitorReceptionAudio")[0])
        monitorCallReceptionRef.current = null
      }
      setMonitorCallStatus(false)
    }

    const startCheckCallStateInterval = () => {
      clearInterval(checkCallStateInterval)
      setLocationConnectionIssue(false)

      // Setting an initial value for this variable so it wont be about last call or even null
      // By setting it to now, it will wait x seconds before showing network connection-
      // if location didn't answer inCallQuestion ever
      lastLocationResToCallState.current = Date.now()

      checkCallStateInterval = setInterval(() => {
        if(!room.current) {
          console.error(1181, "!room.current in checkCallStateInterval")
          clearInterval(checkCallStateInterval)
          return
        }
        if(!locationIDRef.current) {
          console.error(1183, "!locationIDRef.current in checkCallStateInterval")
          clearInterval(checkCallStateInterval)
          return
        }
        if(!callStateRef.current) {
          console.error(1185, "!callStateRef.current in checkCallStateInterval")
          clearInterval(checkCallStateInterval)
          return
        }

        room.current.sendEndpointMessage(locationIDRef.current, {type: "inCallQuestion"});

        if(lastLocationResToCallState.current && lastLocationResToCallState.current < (Date.now() - (7 * 1000))) {
          setLocationConnectionIssue(true)
        } else {
          setLocationConnectionIssue(false)
        }
      }, 2 * 1000);
    }

    const updateReceiverConstraints=()=>{
      if(!locationVideoTrack) return
      setReceiverCons()
    }

    return (
        <div className='doctor-root'>
            {showLoading ? 
              <div>
                <div style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "#000",
                  opacity: 0.75,
                  zIndex: 2000,
                }}></div>
                <div style={{
                  position: "absolute",
                  width: "50px",
                  height: "50px",
                  zIndex: 2001,
                  top: "50%",
                  left: "50%",
                  margin: "-25px 0 0 -25px",
                }}>
                  <ReactLoading type={"spin"} color={"#0085d2"} />
                </div>
              </div>
            : ""}
            {
              !connections.internet ?
                <Connection msg="It seems like there is no internet connection. Retrying…"/>
              : !connections.server ?
                <Connection msg="We are currently upgrading and updating our service. 
                  We apologize for any inconvenience this may cause. 
                  Please be patient while we apply these necessary changes, 
                  your system will be back up shortly."/>
              :
              <div className='main_body_area'>
                <UserArea
                  changeUserStatus={changeUserStatus}
                  onLocalTracks={onLocalTracks}
                  userStatus={userStatus}
                  remoteUsers={receptionData}
                  mic={mic}
                  camera={camera}
                  joinState={joinState}
                  localConnectionQuality={localConnectionQuality}
                  setMic={setMic}
                  setCamera={setCamera}
                />
                <div className='main_body_location_area' id="mainBodyLocationArea">
                    <LocationArea
                        handleClickLocationItem={handleClickLocationItem}
                        handleClickHoldLocation={handleClickHoldLocation}
                        handleItemCall={handleItemCall}
                        handleLocationPlay={handleLocationPlay}
                        handleLocationCall={handleLocationCall}
                        handleItemSleep={handleItemSleep}
                        playState={playState}
                        callState={callState}
                        glowState={glowState}
                        locationName={locationName}
                        remoteLocations={locationData}
                        allLocationData={[...allLocations]}
                        localUserData={localUserData}
                        locationEngaged={locationEngaged}
                        monitorCallStatus={monitorCallStatus}
                        monitorCallHold={monitorCallHold}
                        startMonitorCall={startMonitorCall}
                        handleOpenModalPayReq={handleOpenModalPayReq}
                        showPrintFileModal={showPrintFileModal}
                        handleRequestForScan={handleRequestForScan}
                        micro={mic}
                        camera={camera}
                        handleDeviceManagement={handleDeviceManagement}
                        handleOpenMessagingBoard={handleOpenMessagingBoard}
                        captureLocationCameraImage={captureLocationCameraImage}

                    />
                    <LocationNameBox name={locationName} id={locationID} locationConnectionIssue={locationConnectionIssue}/>
                    <LocationCallTagMemo locations={locationData} callState={callState} locationID={locationID}  />
                    <ReceptionFooter
                      handleOpenModalPayReq={handleOpenModalPayReq}
                      handleRequestForScan={handleRequestForScan}
                      startCardDetection={startCardDetection}
                      showPrintFileModal={showPrintFileModal}
                      handleLocalCamera={handleLocalCamera}
                      handleLocalMic={handleLocalMic}
                      handleLocationCall={handleLocationCall} 
                      handleLocationPlay={handleLocationPlay} 
                      handleOpenGallery={handleOpenGallery}
                      toggleShareScreen={toggleShareScreen}
                      callState={callState} 
                      locationEngaged={locationEngaged} 
                      allLocations={allLocations} 
                      playState={playState} 
                      mic={mic} 
                      camera={camera}
                      handleExitFullScreenEngaged={handleExitFullScreenEngaged}
                      captureLocationCameraImage={()=>{captureLocationCameraImage(locationEngagedRef.current)}}
                      setShowModalSetting={setShowModalSetting}
                      handleDeviceManagement={()=>handleDeviceManagement(locationEngaged)}
                      callLoading={callLoading}
                      handleOpenMessagingBoard={()=>handleOpenMessagingBoard(locationEngaged)}
                      monitorCallStatus={monitorCallStatus}
                      openIssueReporter={openIssueReporter}
                    />
                </div>
                <RequestPaymentModal
                    showModal={payReqModal}
                    handleClose={handleCloseModalPayReq}
                    locationUsername={locationEngaged}
                    allLocations={allLocations}
                />
                <PrintRequestModal
                    allLocations={allLocations}
                    locationEngaged={locationEngaged}
                />
                <ScanRequestModal 
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  open={openScanRequestModal}
                  handleClose={handleCloseScanRequestModal}
                />
                <PrintFileRequestModal
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  externalShowModal={printFileModalShow}
                  externalClose={closePrintFileModal}
                />
                <CardDetectionRequest
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  open={openCardDetection}
                  handleClose={closeCardDetection}
                />
                <GalleryModal showModal={showGalleryModal} handleClose={handleCloseGallery} defaultBatch={defaultBatch}/>
                <ReportIssueMemo 
                  allLocations={allLocations} 
                  engagedLocation={locationEngaged} 
                  open={issueReporterOpen} 
                  handleClose={closeIssueReporter}
                  handleOpen={openIssueReporter}
                />
                <SettingsModalMemo
                  showModal={showModalSetting}
                  handleClose={()=>setShowModalSetting(false)}
                  toggleBlur={toggleBlur}
                  callState={callState} 
                  nonElectronUserLeave={nonElectronUserLeave}
                  updateReceiverConstraints={updateReceiverConstraints}
                  />
                <MessagingBoardModal 
                  showModal={showModalMBoard}
                  handleClose={()=>setShowModalMBoard(false)}
                  location={locationMessagingBoard}
                />
                <UserDeviceManagementMemo
                locationVolume={locationVolume}
                setLocationVolume={setLocationVolume}
                open={openDeviceManagement}
                handleClose={closeDeviceManagement}
                locationUsername={locationUsernameForDeviceManager}/>
              </div>
            }
        </div >
    )
}

Doctor.prototype = {

}

export default withRouter(Doctor);
